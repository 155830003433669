import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";
/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import doc from "../../assets/images/docimg.png";
import { LuSend } from "react-icons/lu";

const OfferEdit = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [offerItems, setOfferItems] = useState([]);
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [deadLine, setDeadLine] = useState(new Date());
  const [vat, setVat] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [stockCards, setStockCards] = useState([]);
  const [stockCardsOpt, setStockCardsOpt] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsOpt, setAccountsOpt] = useState([]);
  const [title, setTitle] = useState("");
  const [offerName, setOfferName] = useState("");
  const [editUser, setEditUser] = useState([]);
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [parentNumber, setParentNumber] = useState(null);
  const [revisionOffers, setRevisionOffers] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [file, setFile] = useState(null);
  const [dataImages, setDataImages] = useState(null);
  const [offerType, setOfferType] = useState({});
  const [offerTypes, setOfferTypes] = useState([]);

  useEffect(() => {
    setLoading(true);
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=4`
        )
        .then((res) => {
          if (
            res.data.assigmentID === undefined ||
            !res.data.updatePermission
          ) {
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboards");
              }
            });
            return;
          }

          Promise.all([
            api.get(
              `/api/Account/GetAccounts?customerID=${user.country}&typeID=25&pageNumber=1&pageSize=99999`
            ),
            api.get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&typeIDs=28&typeIDs=29&typeIDs=1663&pageNumber=1&pageSize=99999`
            ),
            api.get(`/api/Common/GetKeywords?customerID=${user.country}`),
            api.get("/api/Common/GetVat"),
            api.get("/api/Common/GetCurrencies"),
            api.get("/api/Common/GetParameters?parametername=DemandType "),
            api.get(
              `/api/Offer/Get?id=${params.id}&customerID=${user.country}`
            ),
          ])
            .then(
              ([
                accountsRes,
                stockCardsRes,
                keywordsRes,
                vatRes,
                currenciesRes,
                typeRes,
                offerRes,
              ]) => {
                setAccounts(accountsRes.data.items);
                const newDataAccounts = accountsRes.data.items.map((item) => ({
                  value: item.accountID,
                  label: item.name,
                }));

                setAccountsOpt(newDataAccounts);
                setStockCards(stockCardsRes.data.items);
                const newDataStockCards = stockCardsRes.data.items.map(
                  (item) => ({
                    value: item.stockCardID,
                    label: `${item.code} - ${item.name}`,
                    type: item.type,
                  })
                );

                setStockCardsOpt(newDataStockCards);
                const keywordsData = keywordsRes.data?.map((item) => ({
                  value: item,
                  label: item,
                }));
                setCustomerKey(keywordsData);
                const optionsVat = vatRes.data.map((item) => ({
                  value: item,
                  label: item,
                }));
                setVat(optionsVat);

                const currencyData = currenciesRes.data?.map((item) => ({
                  value: item.currencyID,
                  label: item.name,
                }));
                setCurrency(currencyData);

                const typeData = typeRes.data?.map((item) => ({
                  label: item.parameterValue2,
                  value: item.parameterID,
                }));
                setOfferTypes(typeData);

                // Teklif detaylarını güncelle
                setData(offerRes.data);
                setDataImages(offerRes.data.imageFiles);
                setTitle(offerRes.data.title);
                setOfferName(offerRes.data.name);
                setOfferType({
                  label: offerRes.data.offerTypeString,
                  value: offerRes.data.offerType,
                });
                setSelectedCurrency({
                  label: offerRes.data.currencyName,
                  value: offerRes.data.currencyID,
                });
                setSelectedAccount({
                  value: offerRes.data.account.accountID,
                  label: offerRes.data.account.name,
                });
                setOfferItems(offerRes.data.offerItems);
                setParentNumber(offerRes.data.parentID); // parentNumber'ı kaydediyoruz
                setEditUser(offerRes.data);
                setStartDate(new Date(offerRes.data.offerDate));
                setFinishDate(new Date(offerRes.data.expiryDate));
                setDeadLine(
                  offerRes.data.deadLine
                    ? new Date(offerRes.data.deadLine)
                    : null
                );
                setDescription(offerRes.data.description);

                const keywordItems = JSON.parse(offerRes.data.keywords).map(
                  (item) => ({ value: item, label: item, __isNew__: true })
                );
                setKeywords(keywordItems);
              }
            )
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err));
    }
  }, [user, params.id]);

  useEffect(() => {
    // `user` ve `user.country` mevcutsa işlem yapıyoruz
    if (user && user.country) {
      const offerID = parentNumber || params.id; // parentNumber varsa onu, yoksa params.id'yi kullan

      if (offerID) {
        api
          .get(
            `/api/Offer/GetOfferRevisionSummary?customerID=${user.country}&offerID=${offerID}`
          )
          .then((revisionOffersRes) => {
            setRevisionOffers(revisionOffersRes.data || []); // Boş dizi olarak güncelliyoruz
          })
          .catch((err) => console.log(err));
      }
    }
  }, [parentNumber, params.id, user]); // `user`, `parentNumber`, veya `params.id` değiştiğinde tetiklenir

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...offerItems];
    list[index][name] = value;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //ara toplam
    let altToplam = list[index].quantity * list[index].unitPrice;
    list[index].price = altToplam;

    //KDV
    list[index].taxPrice = tax;

    setOfferItems(list);
  };

  const handleSelectChange = async(e, index) => {
    const list = [...offerItems];
    list[index].name = e.label;
    list[index].stockCardID = e.value;
    const stockCardsValue = stockCards.find(
      (item) => item.stockCardID === e.value
    );
    list[index].unitTypeString = stockCardsValue.unitTypeString;
    list[index].unitPrice = stockCardsValue.salesPrice;
    list[index].taxRate = stockCardsValue.vat;
    try {
      const res = await api.get(
        `/api/WareHouse/GetSellableStockCardQuantity?customerID=${user.country}&stockCardID=${e.value}`
      );

      list[index].sellableStockQuantity = res.data.sellableStockQuantity;
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setOfferItems(list);
      setLoading(false);
    }
    setOfferItems(list);
  };


  const handleSelectChangeVat = (e, index) => {
    const list = [...offerItems];
    list[index].taxRate = e;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //KDV
    list[index].taxPrice = tax;

    setOfferItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...offerItems];
    list.splice(index, 1);
    setOfferItems(list);
  };

  const handleServiceAdd = () => {
    setOfferItems([
      ...offerItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        discount: Number(),
        name: "",
        taxRate: "",
        totalPrice: Number(),
        price: "",
        taxPrice: "",
        description: "",
        offerItemID: Number(),
        offerID: params.id,
        customerID,
        stockCardID: "",
      },
    ]);
  };

  const handleManuelServiceAdd = () => {
    setOfferItems([
      ...offerItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        discount: Number(),
        name: "",
        taxRate: "",
        totalPrice: Number(),
        price: "",
        taxPrice: "",
        description: "",
        offerItemID: Number(),
        offerID: params.id,
        customerID,
        stockCardID: "",
      },
    ]);
  };

  //////////////////////////

  const find = accounts.find(
    (item) => item.accountID === selectedAccount.value
  );

  ////////calculations
  const resultDiscount = offerItems?.length
    ? offerItems
        .map((sayi) => Number(sayi.discount))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultTotalPrice = offerItems?.length
    ? offerItems
        .map((sayi) => Number(sayi.totalPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultSubTotal = offerItems?.length
    ? offerItems
        .map((sayi) => Number(sayi.price))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultKdvTotal = offerItems?.length
    ? offerItems
        .map((sayi) => Number(sayi.taxPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const putData = {
    offerID: params.id,
    customerID,
    accountID: selectedAccount.value,
    currencyID: selectedCurrency.value,
    offerNo: "",
    name: offerName,
    title: title,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    offerDate: startDate,
    expiryDate: finishDate,
    price: resultSubTotal,
    lineDiscounts: resultDiscount,
    taxPrice: resultKdvTotal,
    totalPrice: resultTotalPrice,
    deadLine,
    type: 0,
    offerType: offerType?.value,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    offerItems
  };
  const postData = {
    parentID: parentNumber || params.id,
    customerID,
    accountID: selectedAccount.value,
    currencyID: selectedCurrency.value,
    offerNo: "",
    name: offerName,
    title: title,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    offerDate: startDate,
    expiryDate: finishDate,
    price: resultSubTotal,
    lineDiscounts: resultDiscount,
    taxPrice: resultKdvTotal,
    totalPrice: resultTotalPrice,
    deadLine,
    type: 3785,
    offerType: offerType?.value,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    offerItems,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Token'ı localStorage veya sessionStorage'dan alıyoruz
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    // Teklif durumu reddedilmişse (status = 3790) direkt revizyon kaydediyoruz
    if (data?.status === 3790) {
      setLoading(true); // Yükleniyor durumunu açıyoruz
      api
        .post("/api/Offer/Post/Post", postData, {
          headers: {
            Authorization: `Bearer ${token}`, // Token'ı header'a ekliyoruz
          },
        })
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Teklif başarılı bir şekilde revize edildi!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          console.log(err);
          const errorMessageKey = err.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, {
                defaultValue: errorMessageKey,
              })
            : t("errors.UnknownError", {
                defaultValue: "Bilinmeyen bir hata",
              });
          Swal.fire(translatedError); // Hata durumunda modal gösteriyoruz
        })
        .finally(() => setLoading(false)); // Yükleniyor durumunu kapatıyoruz
    } else {
      // Eğer teklif durumu reddedilmemişse, normal onay süreci devam eder
      Swal.fire({
        title: "Revizyon olarak kayıt edilsin mi?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet",
        denyButtonText: "Hayır",
        denyButtonColor: "#050A27",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isDenied) {
          setLoading(true); // Yükleniyor durumunu açıyoruz
          api
            .put("/api/Offer/Put/Put", putData, {
              headers: {
                Authorization: `Bearer ${token}`, // Token'ı header'a ekliyoruz
              },
            }) // API'yi çağırıyoruz
            .then((res) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Teklif başarılı bir şekilde değiştirildi!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((err) => {
              console.log(err);
              const errorMessageKey = err.response?.data;
              const translatedError = errorMessageKey
                ? t(`errors.${errorMessageKey}`, {
                    defaultValue: errorMessageKey,
                  })
                : t("errors.UnknownError", {
                    defaultValue: "Bilinmeyen bir hata",
                  });
              Swal.fire(translatedError); // Hata durumunda modal gösteriyoruz
            })
            .finally(() => setLoading(false)); // Yükleniyor durumunu kapatıyoruz
        } else if (result.isConfirmed) {
          api
            .post("/api/Offer/Post/Post", postData, {
              headers: {
                Authorization: `Bearer ${token}`, // Token'ı header'a ekliyoruz
              },
            }) // API'yi çağırıyoruz
            .then((res) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Teklif başarılı bir şekilde revize edildi!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((err) => {
              console.log(err);
              const errorMessageKey = err.response?.data;
              const translatedError = errorMessageKey
                ? t(`errors.${errorMessageKey}`, {
                    defaultValue: errorMessageKey,
                  })
                : t("errors.UnknownError", {
                    defaultValue: "Bilinmeyen bir hata",
                  });
              Swal.fire(translatedError); // Hata durumunda modal gösteriyoruz
            })
            .finally(() => setLoading(false)); // Yükleniyor durumunu kapatıyoruz
        }
      });
    }
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    return `${gun}/${ay}/${yil}`;
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleDocSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=106&relationID=${params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosyalar yüklendi!",
        showConfirmButton: false,
        timer: 1500,
      });

      // SweetAlert tamamlandıktan sonra sayfa yenile
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });

      // Hata durumunda da SweetAlert'ten sonra sayfa yenile
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  const postControlAprrovalOffer = async (offerID, customerID) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Token bulunamadı, lütfen tekrar giriş yapın.",
        showConfirmButton: false,
        timer: 1500,
      });
      return; // Token yoksa işlem yapılmasın
    }

    Swal.fire({
      title: "Kontrol onayı vermek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, ver!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await api.put(
            `/api/Offer/PutOfferStatus/PutOfferStatus?customerID=${customerID}&offerID=${offerID}&status=3788`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`, // Authorization başlığını ekliyoruz
              },
            }
          );
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Kontrol onayı verildi!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };
  const postConfirmOffer = async (offerID, customerID) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Token bulunamadı, lütfen tekrar giriş yapın.",
        showConfirmButton: false,
        timer: 1500,
      });
      return; // Token yoksa işlem yapılmasın
    }

    Swal.fire({
      title: "Teklifi onaylamak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, onayla!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await api.put(
            `/api/Offer/PutOfferStatus/PutOfferStatus?customerID=${customerID}&offerID=${offerID}&status=3789`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`, // Authorization başlığını ekliyoruz
              },
            }
          );
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Teklif onaylandı!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleReject = async (offerID, customerID) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Token bulunamadı, lütfen giriş yapın!",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    const { value: reason } = await Swal.fire({
      title: "Reddetme Sebebinizi Girin",
      input: "textarea",
      inputPlaceholder: "Reddetme sebebinizi buraya yazın...",
      showCancelButton: true,
      confirmButtonText: "Reddet",
      cancelButtonText: "İptal",
      inputValidator: (value) => {
        if (!value) {
          return "Lütfen reddetme sebebini yazın!";
        }
      },
    });

    if (reason) {
      try {
        const response = await api.put(
          `/api/Offer/PutOfferStatus/PutOfferStatus?customerID=${customerID}&offerID=${offerID}&desc=${encodeURIComponent(
            reason
          )}&status=3790`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Teklif reddedildi!",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      } catch (error) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Hata oluştu, işlem başarısız!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const handleCancel = async (offerID, customerID) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Token bulunamadı, lütfen giriş yapın!",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    const { value: reason } = await Swal.fire({
      title: "İptal Etme Sebebinizi Girin",
      input: "textarea",
      inputPlaceholder: "İptal etme sebebinizi buraya yazın...",
      showCancelButton: true,
      confirmButtonText: "İptal Et",
      cancelButtonText: "İptal",
      inputValidator: (value) => {
        if (!value) {
          return "Lütfen iptal etme sebebini yazın!";
        }
      },
    });

    if (reason) {
      try {
        const response = await api.put(
          `/api/Offer/PutOfferStatus/PutOfferStatus?customerID=${customerID}&offerID=${offerID}&desc=${encodeURIComponent(
            reason
          )}&status=3792`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Token'ı header'a ekliyoruz
            },
          }
        );
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Teklif iptal edildi!",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      } catch (error) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Hata oluştu, işlem başarısız!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const postOfferToOrder = async (offerID, customerID) => {
    Swal.fire({
      title: "Siparişe göndermek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, gönder!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          await api
            .post(
              `/api/Order/PostOfferToOrder/PostOfferToOrder?offerID=${offerID}&customerID=${customerID}`
            )
            .then((res) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Siparişe gönderildi!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((error) => {
              const errorMessageKey = error.response?.data;
              const translatedError = errorMessageKey
                ? t(`errors.${errorMessageKey}`, {
                    defaultValue: errorMessageKey,
                  })
                : t("errors.UnknownError", {
                    defaultValue: "Bilinmeyen bir hata",
                  });
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `${translatedError}`,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "İşlem başarısız oldu!",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
        }
      }
    });
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 3787:
        return { label: "Yeni", color: "bg-gray-500 text-gray-100" };
      case 3788:
        return {
          label: "Konrol Edildi",
          color: "bg-yellow-700 text-yellow-100",
        };
      case 3789:
        return { label: "Onaylandı", color: "bg-green-700 text-green-100" };
      case 3790:
        return { label: "Reddedildi", color: "bg-red-700 text-red-100" };
      case 3792:
        return { label: "İptal oldu", color: "bg-gray-700 text-gray-100" };
      default:
        return { label: "Tanımsız", color: "bg-gray-700 text-gray-100" };
    }
  };

  console.log(data);

  return (
    <>
      <Header />

      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Satış Teklifleri", href: "/offers" },
                { label: "Satış Teklifi Güncelle", href: "" },
              ]}
            />
            <div className="mx-auto my-20 rounded-[3px] min-h-screen flex justify-between w-[95%] gap-9">
              <form
                onSubmit={handleSubmit}
                className="w-[67%] bg-gray-100 p-12"
              >
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title ">
                    Satış Teklifi Güncelle
                  </h1>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlık
                    </label>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="offerName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı
                    </label>
                    <input
                      value={offerName}
                      onChange={(e) => setOfferName(e.target.value)}
                      type="text"
                      id="offerName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cari
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="name"
                      onChange={(e) => setSelectedAccount(e)}
                      value={selectedAccount}
                      options={accountsOpt}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="taxOffice"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi Dairesi
                    </label>
                    <input
                      value={find?.taxOffice}
                      readOnly
                      type="text"
                      id="taxOffice"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi No
                    </label>
                    <input
                      value={find?.taxNumber}
                      readOnly
                      type="text"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler
                    </label>
                    <CreatableSelect
                      value={keywords}
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                    <div>
                      <label
                        htmlFor="visitors"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Para Birimi
                      </label>
                      <Select
                        maxMenuHeight={200}
                        onChange={(e) => setSelectedCurrency(e)}
                        value={selectedCurrency}
                        options={currency}
                        classNamePrefix="custom-select"
                        placeholder="Seçiniz..."
                      />
                    </div>
                  <div>
                    <label
                      htmlFor="deadLine"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Termin Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={deadLine}
                      onChange={(date) => setDeadLine(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama
                    </label>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Teklif Tipi
                    </label>
                    <Select
                      value={offerType}
                      onChange={(e) => setOfferType(e)}
                      maxMenuHeight={200}
                      options={offerTypes}
                      required
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Tarih
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Ödeme Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={finishDate}
                      onChange={(date) => setFinishDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div className="flex items-center col-span-2">
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                      id="multiple_files"
                      type="file"
                      multiple
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="bg-gray-900  hover:bg-gray-700  text-white font-bold py-2 px-4 rounded-xl"
                      onClick={() => handleDocSubmit()}
                    >
                      Yükle
                    </button>
                  </div>
                  <div className="col-span-2 my-12">
                    <Splide
                      options={{
                        autoWidth: true,
                        gap: 100,
                        pagination: false,
                        padding: 100,
                      }}
                      aria-label="My Favorite Images"
                    >
                      {dataImages?.map((item, index) => {
                        const imageName = item.split("/").pop();
                        const altText = imageName
                          .split("_")
                          .pop()
                          .split(".")[0];
                        // Resim URL'si kontrolü
                        const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                        return (
                          <SplideSlide key={index}>
                            {isImage ? (
                              <Link to={item}>
                                <img
                                  className="max-w-[200px] cursor-pointer"
                                  src={item}
                                  alt={`Image ${index + 1}`}
                                />
                              </Link>
                            ) : (
                              <Link to={item}>
                                <img
                                  className="max-w-[100px] cursor-pointer"
                                  src={`${doc}`}
                                />
                              </Link>
                            )}
                            <p className="text-center font-medium">
                              {truncateText(altText, 10)}
                            </p>
                          </SplideSlide>
                        );
                      })}
                    </Splide>
                  </div>
                </div>

                <div className="relative overflow-x-auto mb-5">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-3 py-3">
                          Satılabilir Adet
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Fiyat
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Tipi
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İskonto
                        </th>
                        <th scope="col" className="px-6 py-3">
                          KDV
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Toplam
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {offerItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          {singleService.stockCardID === 0 ? (
                            <td className="px-3 py-4">
                              <div className="min-w-[180px]">
                                <input
                                  value={singleService.name}
                                  onChange={(e) =>
                                    handleServiceChange(e, index)
                                  }
                                  name="name"
                                  type="text"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                  required
                                />
                              </div>
                            </td>
                          ) : (
                            <td className="px-3 py-4">
                              <div className="min-w-[180px]">
                                <Select
                                  value={{
                                    value: singleService.stockCardID,
                                    label: singleService.name,
                                  }}
                                  maxMenuHeight={200}
                                  onChange={(e) => handleSelectChange(e, index)}
                                  options={stockCardsOpt}
                                  classNamePrefix="custom-select"
                                />
                              </div>
                            </td>
                          )}

                          <td className="px-3 py-4">
                          <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className={`special-input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                                singleService.quantity >
                                singleService.sellableStockQuantity
                                  ? "red-bg"
                                  : "green-bg"
                              }`}
                              required
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.sellableStockQuantity}
                              name="sellableStockQuantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              disabled
                            />
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.unitPrice}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="unitPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 min-w-[80px]"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            {singleService.unitTypeString ? (
                              <p className="text-[#0C692F] font-semibold text-center">
                                {singleService.unitTypeString}
                              </p>
                            ) : (
                              <p className="text-[#0C692F] font-semibold text-center">
                                -
                              </p>
                            )}
                          </td>
                          <td className="px-3 py-4">
                            <input
                              value={singleService.discount}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="discount"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-3 py-4">
                            <div className="min-w-[80px]">
                              <Select
                                maxMenuHeight={200}
                                value={{
                                  value: singleService.taxRate,
                                  label: singleService.taxRate,
                                }}
                                onChange={(e) =>
                                  handleSelectChangeVat(e.value, index)
                                }
                                options={vat}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>
                          <td className="px-3 py-4 ">
                            <input
                              value={singleService.totalPrice}
                              readOnly
                              name="totalPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 min-w-[100px]"
                              required
                            />
                          </td>
                          <td className="px-3 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                              title="Sil"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <div className="my-5 flex gap-4">
                      <button
                        onClick={handleServiceAdd}
                        type="button"
                        className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg h-10 w-24"
                        title="Ekle"
                      >
                        Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                      <button
                        onClick={handleManuelServiceAdd}
                        type="button"
                        className="bg-[#0C692F] hover:bg-[#0c6930e3] text-white font-bold py-2 px-4 rounded-lg w-36"
                        title="Ekle"
                      >
                        Manuel Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </div>
                  </table>

                  <div className="resultMoney">
                    <div className="resultMoneyInfo">
                      <h1>Ara Toplam :</h1>
                      <p>{resultSubTotal}</p>
                      <span>{selectedCurrency.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>Satır İndirimi :</h1>
                      <p>{resultDiscount}</p>
                      <span>{selectedCurrency.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>KDV :</h1>
                      <p>{resultKdvTotal}</p>
                      <span>{selectedCurrency.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>Toplam :</h1>
                      <p>{resultTotalPrice}</p>
                      <span>{selectedCurrency.label}</span>
                    </div>
                  </div>
                  <div className="page-users mt-10 p-4 border-t border-gray-300">
                    <h2 className="text-lg font-semibold mb-4">
                      Kullanıcı Bilgileri
                    </h2>
                    <div className="flex justify-between">
                      <div>
                        <p className="font-medium">Oluşturan:</p>
                        <p>{data?.creator || ""}</p>
                      </div>
                      <div>
                        <p className="font-medium">Kontrol Eden:</p>
                        <p>{data?.controllerUser || ""}</p>
                      </div>
                      <div>
                        <p className="font-medium">Onaylayan:</p>
                        <p>{data?.approvingUser || ""}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-5 justify-end">
                  {data?.status === 3788 && (
                    <button
                      type="button"
                      className="butonCl text-white bg-yellow-600 hover:bg-[#caa537] focus:outline-none focus:ring-4 focus:ring-[#050a27d7] font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                      title="Genel onayı ver"
                      onClick={() =>
                        postConfirmOffer(data?.offerID, data?.customerID)
                      }
                    >
                      Genel Onayı Ver <i className="fa-solid fa-check ml-2"></i>
                    </button>
                  )}
                  {data?.status === 3787 && (
                    <button
                      type="button"
                      className="butonCl text-white bg-[#050A27] hover:bg-[#050a27d7] focus:outline-none focus:ring-4 focus:ring-[#050a27d7] font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                      title="Kontrol Onayı Ver"
                      onClick={() =>
                        postControlAprrovalOffer(
                          data?.offerID,
                          data?.customerID
                        )
                      }
                    >
                      Kontrol Onayı Ver{" "}
                      <i className="fa-solid fa-check ml-2"></i>
                    </button>
                  )}
                  {data?.status === 3789 && (
                    <button
                      type="button"
                      className="butonCl text-white bg-[#050A27] hover:bg-[#050a27d7] focus:outline-none focus:ring-4 focus:ring-[#050a27d7] font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                      title="Siparişe Gönder"
                      onClick={() =>
                        postOfferToOrder(data?.offerID, data?.customerID)
                      }
                    >
                      Siparişe Gönder
                      <i className="fa-solid fa-arrow-up ml-2"></i>
                    </button>
                  )}
                  {data?.status !== 3789 && (
                    <>
                      <button
                        type="button"
                        className="butonCl text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-4 focus:ring-gray-400 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                        title="İptal et"
                        onClick={() =>
                          handleCancel(data.offerID, data.customerID)
                        }
                      >
                        İptal et <i className="fa-solid fa-ban ml-2"></i>
                      </button>

                      <button
                        type="button"
                        className="butonCl text-white bg-[#B10F0F] hover:bg-[#b10f0fe1] focus:outline-none focus:ring-4 focus:ring-[#b10f0fe1] font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                        title="Reddet"
                        onClick={() =>
                          handleReject(data.offerID, data.customerID)
                        }
                      >
                        Reddet <i className="fa-solid fa-xmark ml-2"></i>
                      </button>
                    </>
                  )}
                  <button
                    type="submit"
                    className={`butonCl text-white ${
                      data.status === 3792
                        ? "bg-gray-600 cursor-not-allowed"
                        : "bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-[#0c6930e3]"
                    } font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12`}
                    title="Kaydet"
                    disabled={data.status === 3792}
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>

              <div className="w-[33%]">
                <div className="flex items-center gap-2 mb-8">
                  <FaStarOfLife size={15} />
                  <h1 className="font-semibold text-2xl">Teklif Geçmişi</h1>
                </div>
                <div className="relative overflow-x-auto w-full">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs bg-[#050A27] text-white uppercase">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Başlık
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Tarih
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Fiyat
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {revisionOffers?.offers?.length > 0 ? (
                        <>
                          {/* Ana Teklif Satırı */}
                          <tr
                            className={`cursor-pointer hover:bg-gray-100 ${
                              revisionOffers.offerID === parseInt(params.id)
                                ? "bg-gray-200"
                                : "bg-white"
                            }`}
                            onClick={() =>
                              navigate(`/offerEdit/${revisionOffers.offerID}`)
                            }
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <div
                                title={revisionOffers?.title}
                                className="truncate lg:whitespace-normal"
                              >
                                Ana Teklif <br />
                                {window.innerWidth < 768
                                  ? truncateText(revisionOffers?.title, 17)
                                  : revisionOffers?.title}{" "}
                                <br />
                                <span
                                  title={
                                    revisionOffers.cancelledForRejection ||
                                    revisionOffers.reasonForRejection
                                  }
                                  className={`inline-block mt-2 rounded px-2 py-1 text-xs font-semibold ${
                                    getStatusLabel(revisionOffers.status).color
                                  }`}
                                >
                                  {getStatusLabel(revisionOffers.status).label}
                                </span>
                              </div>
                            </th>
                            <td className="px-6 py-4">
                              {formatDate(revisionOffers?.offerDate)}
                            </td>
                            <td className="px-6 py-4 font-semibold">
                              {revisionOffers?.totalPrice}
                            </td>
                          </tr>

                          {/* Revizyon Teklifleri */}
                          {revisionOffers.offers.map((item) => {
                            const isSelected =
                              item.offerID === parseInt(params.id);

                            return (
                              <tr
                                key={item.offerID}
                                className={`hover:bg-gray-100 cursor-pointer ${
                                  isSelected ? "bg-gray-200" : "bg-white"
                                }`}
                                onClick={() =>
                                  navigate(`/offerEdit/${item.offerID}`)
                                }
                              >
                                <th
                                  scope="row"
                                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  <div
                                    title={item?.title}
                                    className="truncate lg:whitespace-normal"
                                  >
                                    {`Revizyon ${item?.version}`} <br />
                                    {window.innerWidth < 768
                                      ? truncateText(item?.title, 17)
                                      : item?.title}
                                    <br />
                                    <span
                                      title={
                                        item.cancelledForRejection ||
                                        item.reasonForRejection
                                      }
                                      className={`inline-block mt-2 rounded px-2 py-1 text-xs font-semibold ${
                                        getStatusLabel(item.status).color
                                      }`}
                                    >
                                      {getStatusLabel(item.status).label}
                                    </span>
                                  </div>
                                </th>
                                <td className="px-6 py-4">
                                  {formatDate(item?.offerDate)}
                                </td>
                                <td className="px-6 py-4 font-semibold">
                                  {item?.totalPrice}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td
                            colSpan="3"
                            className="px-6 py-4 text-center text-gray-500"
                          >
                            Henüz revizyon kaydı bulunmamaktadır.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default OfferEdit;
