import React, { useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import Footer from "../../components/bars/Footer";
import Breadcrumb from "../../components/bars/Breadcrumb";
import ShiftInformationTab from "./ShiftInformationTab";
import GeneralSettingsTab from "./GeneralSettingsTab";
import ZoomInformationTab from "./ZoomInformationTab";
import SmsInformationTab from "./SmsInformationTab";
import EmailInformationTab from "./EmailInformationTab";
import EinvoiceSettingsTab from "./EinvoiceSettingsTab";
import NumericSettingTab from "./NumericSettingTab";
import EwaybillSettingsTab from "./EwaybillSettingsTab";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTabSettings") || "GeneralSettings"
  ); // Varsayılan olarak local storage'dan veya 'AssignerUser' olarak başlat

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem("activeTabSettings", tabName); // Sekme değiştiğinde local storage'a kaydet
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        <div className="flex-grow">
          <Breadcrumb
            breadcrumbData={[{ label: "Ayarlar", href: "" }]}
          />
          <div className="m-12 min-h-screen ">
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-[-30px]">
              <ul className="flex flex-wrap -mb-px ">
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "GeneralSettings"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("GeneralSettings")}
                  >
                    Genel Bilgiler
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "ShiftInformation"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("ShiftInformation")}
                  >
                    Vardiya Bilgileri
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "ZoomInformation"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("ZoomInformation")}
                  >
                    Online Toplantı
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "SmsInformation"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("SmsInformation")}
                  >
                    Sms Ayarları
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "EmailInformation"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("EmailInformation")}
                  >
                    E-mail Ayarları
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "EinvoiceInformation"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("EinvoiceInformation")}
                  >
                    E-Fatura Ayarları
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "EwaybillInformation"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("EwaybillInformation")}
                  >
                    E-İrsaliye Ayarları
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "NumbericInformation"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("NumbericInformation")}
                  >
                   Numaralandırma Ayarları
                  </a>
                </li>
              </ul>
            </div>
            <div>
              {activeTab === "GeneralSettings" && <GeneralSettingsTab />}
              {activeTab === "ShiftInformation" && <ShiftInformationTab />}
              {activeTab === "ZoomInformation" && <ZoomInformationTab />}
              {activeTab === "SmsInformation" && <SmsInformationTab />}
              {activeTab === "EmailInformation" && <EmailInformationTab />}
              {activeTab === "EinvoiceInformation" && <EinvoiceSettingsTab />}
              {activeTab === "NumbericInformation" && <NumericSettingTab />}
              {activeTab === "EwaybillInformation" && <EwaybillSettingsTab />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Settings;
