import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";

const TaskGraph = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user && user.country) {
      api
        .get(
          `/api/Report/CalculateTaskGraph?customerID=${user.country}&startDate=2024-01-01&endDate=2044-01-01`
        )
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  console.log("data", data);
  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg sm:p-3 ">
      <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200">
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-blue-600 truncate">
                  Devam Eden Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-blue-600">
                {data[0]?.totalTasks}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-yellow-600 truncate">
                  Bitiş Onayı Bekleyen Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-yellow-600">
                {data[0]?.awaitingApprovalTaskCount}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-purple-700 truncate">
                  Zamanında Biten Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-purple-700">
                {data[0]?.completedOnTimeTasks}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-red-600 truncate">
                  Geciken Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-red-600">
                {data[0]?.completedLateTasks}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-green-600 truncate">
                  Tamamlanan Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-green-600">
                {data[0]?.completedTasks}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-gray-500 truncate">
                  Tamamlanan Görev Yüzdesi
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-gray-500">
                %{data[0]?.completionPercentage}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TaskGraph;
