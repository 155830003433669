import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import { AiOutlinePlus } from "react-icons/ai/";
import SideBarRight from "../../components/modal/SideBarRight";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import doc from "../../assets/images/docimg.png";
import OpportunityInterviewModal from "./OpportunityInterviewModal";
import OpportunityInterviewEditModal from "./OpportunityInterviewEditModal";

const OpportunityEdit = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [userName, setUsername] = useState("");
  const [opportunityItems, setOpportunityItems] = useState([]);
  const [opportunityInterviews, setOpportunityInterviews] = useState([]);
  const [finishDate, setFinishDate] = useState(new Date());
  const [stockCards, setStockCards] = useState([]);
  const [stockCardsOpt, setStockCardsOpt] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [accountsOpt, setAccountsOpt] = useState([]);
  const [name, setName] = useState("");
  const [openSideModal, setOpenSideModal] = useState(false);
  const [openInterviewModal, setOpenInterviewModal] = useState(false);
  const [openInterviewEditModal, setOpenInterviewEditModal] = useState(false);
  const [interViewID, setInterViewID] = useState(Number());
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [rivals, setRivals] = useState("");
  const [alternatives, setAlternatives] = useState("");
  const [salesProbability, setSalesProbability] = useState(50);
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [file, setFile] = useState(null);
  const [dataImages, setDataImages] = useState(null);
  const [relationMeetings, setRelationMeetings] = useState([]);

  useEffect(() => {
    setLoading(true);
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      setUsername(user.name);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=1045`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&typeID=25&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setAccounts(res.data.items);
              const newDataAccounts = res.data?.items?.map((item) => ({
                value: item.accountID,
                label: item.name,
              }));

              setAccountsOpt(newDataAccounts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Meeting/GetMeetingsByRelation?customerID=${user.country}&meetingRelationTypeID=9&relationID=${params.id}`
            )
            .then((res) => setRelationMeetings(res.data))
            .catch((err) => console.error(err.data));

          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => {
              const newDataUsers = res.data?.map((item) => ({
                value: item.userID,
                label: `${item.name} ${item.surname}`,
              }));
              setUsers(newDataUsers);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&typeIDs=28&typeIDs=29&typeIDs=1663&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setStockCards(res.data.items);
              const newDataStockCards = res.data?.items?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
                type: item.type,
              }));

              setStockCardsOpt(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Opportunity/GetOpportunityInterviews?customerID=${user.country}&opportunityID=${params.id}&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setOpportunityInterviews(res.data);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Opportunity/Get?id=${params.id}&customerID=${user.country}`
            )
            .then((res) => {
              setName(res.data.name);
              setDataImages(res.data.imageFiles);
              setOpportunityItems(res.data.opportunityItems);
              setNotes(res.data.notes);
              setSelectedAccount({
                value: res.data.accountID,
                label: res.data.accountName,
              });
              setSelectedUser({
                value: res.data.sales_UserID,
                label: res.data.salesUser,
              });
              setRivals(res.data.rivals);
              setAlternatives(res.data.alternatives);
              setSalesProbability(res.data.salesProbability);
              setDescription(res.data.description);
              setFinishDate(
                res.data.expiryDate ? new Date(res.data.expiryDate) : null
              );
              const data = JSON.parse(res.data.keywords);
              const newData = data.map((item) => ({
                value: item,
                label: item,
                __isNew__: true,
              }));
              setKeywords(newData);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...opportunityItems];
    list[index][name] = value;
    setOpportunityItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...opportunityItems];
    list[index].name = e.label;
    list[index].stockCardID = e.value;
    const stockCardsValue = stockCards.find(
      (item) => item.stockCardID === e.value
    );
    list[index].unitPrice = stockCardsValue.salesPrice;

    setOpportunityItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...opportunityItems];
    list.splice(index, 1);
    setOpportunityItems(list);
  };

  const handleServiceAdd = () => {
    setOpportunityItems([
      ...opportunityItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        name: "",
        stockCardID: Number(),
        opportunityID: params.id,
      },
    ]);
  };

  //////////////////////////

  const find = accounts.find(
    (item) => item.accountID === selectedAccount.value
  );

  const postData = {
    opportunityID: params.id,
    customerID,
    accountID: selectedAccount.value,
    sales_UserID: selectedUser.value,
    name,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    opportunityNo: "",
    expiryDate: finishDate,
    salesProbability,
    rivals,
    alternatives,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    opportunityItems,
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    api
      .put("/api/Opportunity/Put/Put", postData, {
        headers: {
          Authorization: `Bearer ${token}`, // Token'ı Authorization başlığına ekle
        },
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Fırsat güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/opportunities");
      })
      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  const handleBarClick = (e) => {
    const bar = e.target.getBoundingClientRect();
    const clickX = e.clientX - bar.left; // Tıklama noktası (piksel)
    const newPercentage = Math.round((clickX / bar.width) * 100); // Yüzdeyi hesapla
    setSalesProbability(Math.max(0, Math.min(100, newPercentage))); // 0-100 arasında sınırla
  };

  const handleInputChange = (e) => {
    const value = Math.max(0, Math.min(100, Number(e.target.value))); // 0-100 arasında sınırla
    setSalesProbability(value);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleAddNote = async () => {
    setLoading(true);

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!token) {
      Swal.fire("Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }
    if (!newNote) {
      Swal.fire("Boş not eklenemez.");
      setLoading(false);
      return;
    }

    const noteObject = {
      opportunityID: params.id,
      note: newNote,
      noteDate: new Date(),
      userName: userName,
    };
    console.log(noteObject);

    try {
      const res = await api.post(
        `/api/Opportunity/PostOpportunityNotes/PostOpportunityNotes`,
        noteObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Not oluşturuldu",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleDocSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=4893&relationID=${params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosyalar yüklendi!",
        showConfirmButton: false,
        timer: 1500,
      });

      // SweetAlert tamamlandıktan sonra sayfa yenile
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });

      // Hata durumunda da SweetAlert'ten sonra sayfa yenile
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (interViewID) => {
    try {
      // await
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Opportunity/DeleteOpportunityInterview/DeleteOpportunityInterview?customerID=${user.country}&interviewID=${interViewID}&opportunityID=${params.id}`
            )
            .then(() => {
              setOpportunityInterviews((prevData) =>
                prevData.filter((item) => item.interViewID !== interViewID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Görüşme silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Görüşme silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };
  const handleDeleteNote = async (NoteID) => {
    try {
      Swal.fire({
        title: "Notu silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim!",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Opportunity/DeleteOpportunityNotes/DeleteOpportunityNotes?customerID=${user.country}&opportunityID=${params.id}&noteID=${NoteID}`
            )
            .then(() => {
              // Veriyi JSON.parse ile işle
              const parsedNotes = JSON.parse(notes);

              // NoteID'ye göre filtrele
              const updatedNotes = parsedNotes.filter(
                (item) => item.NoteID !== NoteID
              );

              // State güncelle
              setNotes(JSON.stringify(updatedNotes));

              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Not silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting note:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Not silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    let saat = tarihObjesi.getHours();

    saat = saat % 24;

    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}.${ay}.${yil} - ${saatString}.${dakika} `;
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Fırsatlar", href: "/opportunities" },
                { label: "Fırsat Oluştur", href: "" },
              ]}
            />

            <div className="mx-auto my-20 rounded-[3px] min-h-screen flex justify-between w-[95%] gap-9">
              <form
                onSubmit={handleSubmit}
                className="w-[67%] bg-gray-100 p-12"
              >
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">
                    Fırsat Güncelle
                  </h1>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı
                    </label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cari
                    </label>
                    <Select
                      classNamePrefix="custom-select"
                      maxMenuHeight={200}
                      id="name"
                      onChange={(e) => setSelectedAccount(e)}
                      value={selectedAccount}
                      options={accountsOpt}
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="taxOffice"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Vergi Dairesi
                    </label>
                    <input
                      value={find?.taxOffice}
                      readOnly
                      type="text"
                      id="taxOffice"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi No
                    </label>
                    <input
                      value={find?.taxNumber}
                      readOnly
                      type="text"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="address"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adres
                    </label>
                    <input
                      value={find?.address}
                      readOnly
                      type="text"
                      id="address"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Satış Personeli
                    </label>
                    <Select
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                      options={users}
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="rivals"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Rakipler
                    </label>
                    <input
                      type="text"
                      id="rivals"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={rivals}
                      onChange={(e) => setRivals(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="alternatives"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Alternatifler
                    </label>
                    <input
                      type="text"
                      id="alternatives"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={alternatives}
                      onChange={(e) => setAlternatives(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="salesProbability"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Satış Olaslığı
                    </label>
                    <div className="flex items-center mt-4">
                      <div
                        className="w-full h-5 mr-4 bg-gray-200 rounded dark:bg-gray-700 cursor-pointer"
                        onClick={handleBarClick} // Tıklama olayı
                      >
                        <div
                          className="h-5 bg-yellow-500 rounded"
                          style={{ width: `${salesProbability}%` }}
                        ></div>
                      </div>
                      <input
                        type="number"
                        className="w-16 p-1 text-center border rounded text-sm font-medium text-gray-500 dark:text-gray-400"
                        value={salesProbability}
                        onChange={handleInputChange}
                        min="0"
                        max="100"
                      />
                      <span className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                        %
                      </span>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama
                    </label>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler
                    </label>
                    <CreatableSelect
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      value={keywords}
                      isMulti
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Geçerlilik Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={finishDate}
                      onChange={(date) => setFinishDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div className="flex items-center col-span-2">
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                      id="multiple_files"
                      type="file"
                      multiple
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="bg-gray-900  hover:bg-gray-700  text-white font-bold py-2 px-4 rounded-xl"
                      onClick={() => handleDocSubmit()}
                    >
                      Yükle
                    </button>
                  </div>
                  <div className="col-span-2 my-12">
                    <Splide
                      options={{
                        autoWidth: true,
                        gap: 100,
                        pagination: false,
                        padding: 100,
                      }}
                      aria-label="My Favorite Images"
                    >
                      {dataImages?.map((item, index) => {
                        const imageName = item.split("/").pop();
                        const altText = imageName
                          .split("_")
                          .pop()
                          .split(".")[0];
                        // Resim URL'si kontrolü
                        const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                        return (
                          <SplideSlide key={index}>
                            {isImage ? (
                              <Link to={item}>
                                <img
                                  className="max-w-[200px] cursor-pointer"
                                  src={item}
                                  alt={`Image ${index + 1}`}
                                />
                              </Link>
                            ) : (
                              <Link to={item}>
                                <img
                                  className="max-w-[100px] cursor-pointer"
                                  src={`${doc}`}
                                />
                              </Link>
                            )}
                            <p className="text-center font-medium">
                              {truncateText(altText, 10)}
                            </p>
                          </SplideSlide>
                        );
                      })}
                    </Splide>
                  </div>
                </div>

                <div className="relative overflow-x-auto mb-5">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Fiyat
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {opportunityItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-6 py-4">
                            <div>
                              <Select
                                maxMenuHeight={200}
                                value={{
                                  value: singleService.stockCardID,
                                  label: singleService.name,
                                }}
                                onChange={(e) => handleSelectChange(e, index)}
                                options={stockCardsOpt}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                                menuPortalTarget={document.body} // Menüyü body içinde render eder
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base) => ({
                                    ...base,
                                    // Set fixed width
                                  }),
                                }}
                              />
                            </div>
                          </td>

                          <td className="px-6 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.unitPrice}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="unitPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                              title="Sil"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      onClick={handleServiceAdd}
                      type="button"
                      className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                      title="Ekle"
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>
                </div>
                <div className="relative overflow-x-auto col-span-3 pb-24">
                  <div className="pb-5 flex items-center gap-2">
                    <FaStarOfLife size={15} />
                    <h1 className="text-2xl font-semibold title">
                      Fırsata Ait Toplantılar
                    </h1>
                  </div>
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Toplantı Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Toplantı Tarihi
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {relationMeetings?.length > 0 ? (
                        relationMeetings?.map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b hover:bg-gray-50 cursor-pointer"
                            onClick={() =>
                              navigate(`/meetingCheck/${item.meetingID}`)
                            }
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {item.name}
                            </th>
                            <td className="px-6 py-4">
                              {formatDate(item.startDate)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={3}
                            className="px-6 py-4 text-center text-2xl font-medium"
                          >
                            Henüz kayıtlı bir veri yoktur.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Kaydet"
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
              <div className="w-[33%] flex flex-col gap-10">
                <div>
                  <div className="flex items-center gap-2 mb-4">
                    <FaStarOfLife size={15} />
                    <h1 className="font-semibold text-2xl">Notlar</h1>
                  </div>
                  <div className="mb-4 flex flex-col items-end gap-4">
                    <input
                      type="text"
                      value={newNote}
                      onChange={(e) => setNewNote(e.target.value)}
                      placeholder="Yeni not giriniz..."
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                    <button
                      onClick={handleAddNote}
                      className="text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 w-full"
                    >
                      Not Ekle
                    </button>
                  </div>
                  <div className="relative overflow-x-auto w-full">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                      <thead className="text-xs bg-[#050A27] text-white uppercase">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Not
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Tarih
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Sil
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {notes.length > 0 ? (
                          JSON.parse(notes).map((note, i) => {
                            const dateString = note.NoteDate;
                            let formattedDate;

                            if (dateString.includes("T")) {
                              formattedDate = new Date(
                                dateString
                              ).toLocaleDateString("tr-TR", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              });
                            } else if (dateString.includes(".")) {
                              const [day, month, year] = dateString.split(".");
                              formattedDate = new Date(
                                `${year}-${month}-${day}`
                              ).toLocaleDateString("tr-TR", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              });
                            } else {
                              formattedDate = "Geçersiz Tarih";
                            }

                            return (
                              <tr key={i} className="hover:bg-gray-100">
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-normal break-words">
                                  {note.Note || "Boş Not"}
                                </td>
                                <td className="px-6 py-4 text-gray-500">
                                  {formattedDate}
                                </td>
                                <td className="px-6 py-4">
                                  <button
                                    onClick={() =>
                                      handleDeleteNote(note.NoteID)
                                    }
                                    className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-1 px-2 rounded-lg "
                                    title="Sil"
                                  >
                                    <i className="fa-regular fa-trash-can"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="2"
                              className="px-6 py-4 text-center text-gray-500"
                            >
                              Henüz not bulunmamaktadır.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 mb-4">
                    <FaStarOfLife size={15} />
                    <h1 className="font-semibold text-2xl">Görüşmeler</h1>
                  </div>
                  <div className="mb-4 flex flex-col items-end gap-4">
                    <button
                      onClick={() => setOpenInterviewModal(true)}
                      className="text-white bg-[#eab308] hover:bg-[#eab208e1] focus:outline-none focus:ring-4 focus:ring-yellow-500 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 w-full"
                    >
                      Görüşme Ekle
                    </button>
                  </div>
                  <div className="relative overflow-x-auto w-full">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                      <thead className="text-xs bg-[#050A27] text-white uppercase">
                        <tr>
                          <th scope="col" className="px-3 py-3">
                            Adı
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Tipi
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Durumu
                          </th>
                          <th scope="col" className="px-3 py-3">
                            İşlemler
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {opportunityInterviews.length > 0 ? (
                          opportunityInterviews?.map((item, i) => (
                            <tr key={i} className="hover:bg-gray-100">
                              <td
                                onClick={() => {
                                  setInterViewID(item.interViewID);
                                  setOpenInterviewEditModal(true);
                                }}
                                className="px-3 py-4 font-medium text-gray-900 whitespace-normal break-words cursor-pointer"
                              >
                                {item.contactName}
                              </td>
                              <td
                                onClick={() => {
                                  setInterViewID(item.interViewID);
                                  setOpenInterviewEditModal(true);
                                }}
                                className="px-3 py-4 font-medium text-gray-900 whitespace-normal break-words cursor-pointer"
                              >
                                {item.typeString}
                              </td>
                              <td
                                onClick={() => {
                                  setInterViewID(item.interViewID);
                                  setOpenInterviewEditModal(true);
                                }}
                                className="px-3 py-4 font-medium text-gray-900 whitespace-normal break-words cursor-pointer"
                              >
                                {item.statusString}
                              </td>
                              <td className="px-3 py-4">
                                <button
                                  onClick={() => handleDelete(item.interViewID)}
                                  className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-1 px-2 rounded-lg "
                                  title="Sil"
                                >
                                  <i className="fa-regular fa-trash-can"></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="4"
                              className="px-6 py-4 text-center text-gray-500"
                            >
                              Henüz görüşme bulunmamaktadır.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div>
                <button className="absolute top-36 right-0 bg-[#050A27] rounded-l-lg">
                  <AiOutlinePlus
                    size={40}
                    color="white"
                    onClick={() => setOpenSideModal(true)}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        {openSideModal === true && (
          <SideBarRight onCancel={() => setOpenSideModal(false)} />
        )}
        {openInterviewModal === true && (
          <OpportunityInterviewModal
            id={params.id}
            onCancel={() => setOpenInterviewModal(false)}
          />
        )}
        {openInterviewEditModal === true && (
          <OpportunityInterviewEditModal
            interViewID={interViewID}
            id={params.id}
            onCancel={() => setOpenInterviewEditModal(false)}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default OpportunityEdit;
