import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Demands from "./pages/demands";
import { useDispatch } from "react-redux";
import DemandDetail from "./pages/demands/DemandDetail";
import DemandAdd from "./pages/demands/DemandAdd";
import DemandEdit from "./pages/demands/DemandEdit";
import Invoices from "./pages/invoices";
import InvoiceDetail from "./pages/invoices/invoiceDetail";
import InvoiceAdd from "./pages/invoices/invoiceAdd";
import InvoiceEdit from "./pages/invoices/invoiceEdit";
import Offers from "./pages/offers";
import OfferEdit from "./pages/offers/offerEdit";
import OfferAdd from "./pages/offers/OfferAdd";
import OfferDetail from "./pages/offers/offerDetail";
import StockCards from "./pages/stockCards";
import StockCardsAdd from "./pages/stockCards/StockCardsAdd";
import StockCardsEdit from "./pages/stockCards/StockCardsEdit";
import Accounts from "./pages/accounts";
import AccountAdd from "./pages/accounts/AccountAdd";
import AccountEdit from "./pages/accounts/AccountPut";
import Orders from "./pages/orders";
import OrderAdd from "./pages/orders/OrderAdd";
import OrderDetail from "./pages/orders/OrderDetail";
import OrderEdit from "./pages/orders/OrderEdit";
import Deneme from "./pages/deneme";
import Users from "./pages/users";
import UserAdd from "./pages/users/UserAdd";
import UserEdit from "./pages/users/UserEdit";
import Roles from "./pages/roles";
import RoleAdd from "./pages/roles/RoleAdd";
import RoleIdentify from "./pages/roles/RoleIdentify";
import WorkFlow from "./pages/workflow";
import Error from "./pages/Error";
import { getUser } from "./redux/userDataSlice";
import WorkFlowList from "./pages/workflow/WorkFlowList";
import WorkFlowEdit from "./pages/workflow/WorkFlowEdit";
import WorkFlowAddForm from "./pages/workflow/WorkFlowAddForm";
import Forms from "./pages/form";
import FormBuilder from "./pages/form/FormBuilder";
import FormBuilderSchema from "./pages/form/FormBuilderSchema";
import FormBuilderSchemaEdit from "./pages/form/FormBuilderSchemaEdit";
import FormBuilderSchemaDetail from "./pages/form/FormBuilderSchemaDetail";
import Notifications from "./pages/notifications";
import Tasks from "./pages/tasks";
import UserProducts from "./pages/users/UserProducts";
import StockCardsProducts from "./pages/stockCards/StockCardsProduct";
import AccountProducts from "./pages/accounts/AccountProducts";
import TaskAdd from "./pages/tasks/TaskAdd";
import TasksByAssignedUserDetail from "./pages/tasks/TasksByAssignedUserDetail";
import TasksByCreatorUserEdit from "./pages/tasks/TasksByCreatorUserEdit";
import Projects from "./pages/projects";
import ProjectAdd from "./pages/projects/ProjectAdd";
import ProjectEdit from "./pages/projects/ProjectEdit";
import ProjectDetail from "./pages/projects/ProjectDetail";
import Meetings from "./pages/meetings";
import MeetingAdd from "./pages/meetings/MeetingAdd";
import MeetingEdit from "./pages/meetings/MeetingEdit";
import MeetingCheck from "./pages/meetings/MeetingCheck";
import Departments from "./pages/departments";
import DepartmentAdd from "./pages/departments/DepartmentAdd";
import DepartmentEdit from "./pages/departments/DepartmentEdit";
import DepartmentDetail from "./pages/departments/DepartmentDetail";
import MyMeetings from "./pages/my-meetings";
import MeetingLogin from "./pages/my-meetings/MeetingLogin";
import FormView from "./pages/formview";
import Calendars from "./pages/calendars";
import Settings from "./pages/settings/Settings";
import OrganazationSchema from "./pages/settings/OrganazationSchema";
import StockCardBOM from "./pages/stockCardBOM";
import Machines from "./pages/machines";
import MachineAdd from "./pages/machines/MachineAdd";
import MachineEdit from "./pages/machines/MachineEdit";
import Operations from "./pages/operations";
import OperationAdd from "./pages/operations/OperationAdd";
import OperationEdit from "./pages/operations/OperationEdit";
import Personels from "./pages/personels";
import PersonelAdd from "./pages/personels/PersonelAdd";
import PersonelEdit from "./pages/personels/PersonelEdit";
import PersonelDetail from "./pages/personels/PersonelDetail";
import PersonelWageAdd from "./pages/personels/PersonelWageAdd";
import PersonelWages from "./pages/personels/PersonelWages";
import PersonelWageEdit from "./pages/personels/PersonelWageEdit";
import PersonelShifts from "./pages/shifts";
import PersonelShiftAdd from "./pages/shifts/PersonelShiftAdd";
import GeneralShiftAdd from "./pages/shifts/GeneralShiftAdd";
import MachineGroups from "./pages/machines/MachineGroups";
import MachineGroupAdd from "./pages/machines/MachineGroupAdd";
import MachineGroupEdit from "./pages/machines/MachineGroupEdit";
import OperationGroups from "./pages/operations/OperationGroups";
import OperationGroupAdd from "./pages/operations/OperationGroupAdd";
import OperationGroupEdit from "./pages/operations/OperationGroupEdit";
import PersonelGroups from "./pages/personels/PersonelGroups";
import PersonelGroupAdd from "./pages/personels/PersonelGroupAdd";
import PersonelGroupEdit from "./pages/personels/PersonelGroupEdit";
import PersonelGroupDetail from "./pages/personels/PersonelGroupDetail";
import ShiftDetails from "./pages/shifts/ShiftDetails";
import WorkOrders from "./pages/workOrders";
import WorkOrderAdd from "./pages/workOrders/WorkOrderAdd";
import StockCardBomAdd from "./pages/stockCardBOM/StockCardBomAdd";
import StockCardBomEdit from "./pages/stockCardBOM/StockCardBomEdit";
import WareHouses from "./pages/wareHouse";
import WareHouseAdd from "./pages/wareHouse/WareHouseAdd";
import WareHouseEdit from "./pages/wareHouse/WareHouseEdit";
import ShippingSlips from "./pages/wareHouse/ShippingSlips";
import ShippingSlipAdd from "./pages/wareHouse/ShippingSlipAdd";
import WareHouseSlipEdit from "./pages/wareHouse/WareHouseSlipEdit";
import WayBills from "./pages/wayBills";
import WayBillAdd from "./pages/wayBills/WayBillAdd";
import WayBillDetail from "./pages/wayBills/WayBillDetail";
import WayBillEdit from "./pages/wayBills/WayBillEdit";
import WorkOrderClosing from "./pages/workOrders/WorkOrderClosing";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./routing/PrivateRoute";
import WorkOrderDetail from "./pages/workOrders/WorkOrderDetail";
import WorkOrderClosedDetail from "./pages/workOrders/WorkOrderClosedDetail";
import QualityFormsCheck from "./pages/wareHouse/QualityFormsCheck";
import SalesWaybills from "./pages/wayBills/SalesWaybills";
import ProjectTaskAdd from "./pages/projects/ProjectTaskAdd";
import SalesWaybillAdd from "./pages/wayBills/SalesWaybillAdd";
import SalesWaybillEdit from "./pages/wayBills/SalesWaybillEdit";
import EmailConfirm from "./pages/EmailConfirm";
import ManualWorkOrderAdd from "./pages/workOrders/ManualWorkOrderAdd";
import PurchaseInvoices from "./pages/invoices/purchaseInvoice/PurchaseInvoices";
import PurchaseInvoiceAdd from "./pages/invoices/purchaseInvoice/PurchaseInvoiceAdd";
import PurchaseInvoiceEdit from "./pages/invoices/purchaseInvoice/PurchaseInvoiceEdit";
import PreProductionAdd from "./pages/workOrders/PreProductionAdd";
import PurchaseOrders from "./pages/orders/purchaseOrders/PurchaseOrders";
import PurchaseOrderAdd from "./pages/orders/purchaseOrders/PurchaseOrderAdd";
import PurchaseOrderEdit from "./pages/orders/purchaseOrders/PurchaseOrderEdit";
import PurchaseOffers from "./pages/offers/purchaseOffers/PurchaseOffers";
import PurchaseOfferAdd from "./pages/offers/purchaseOffers/PurchaseOfferAdd";
import PurchaseOfferEdit from "./pages/offers/purchaseOffers/PurchaseOfferEdit";
import PurchaseOffersTabs from "./pages/offers/PurchaseOffersTabs";
import OfferWithDemandAdd from "./pages/offers/OfferWithDemandAdd";
import PersonsOvertimeAdd from "./pages/shifts/PersonsOvertimeAdd";
import ShippingAdd from "./pages/shipping/ShippingAdd";
import ShippingEdit from "./pages/shipping/ShippingEdit";
import ShippingDetail from "./pages/shipping/ShippingDetail";
import Shipping from "./pages/shipping";
import Suppliers from "./pages/suppliers";
import SupplierAdd from "./pages/suppliers/SupplierAdd";
import SupplierEdit from "./pages/suppliers/SupplierEdit";
import PotentialAccounts from "./pages/potentialAccounts";
import PotentialAccountAdd from "./pages/potentialAccounts/PotentialAccountAdd";
import PotentialAccountEdit from "./pages/potentialAccounts/PotentialAccountEdit";
import Opportunities from "./pages/opportunities";
import OpportunityAdd from "./pages/opportunities/OpportunityAdd";
import OpportunityEdit from "./pages/opportunities/OpportunityEdit";
import ShippingOrderAdd from "./pages/shipping/ShippingOrderAdd";
import Register from "./pages/register";
import PurchaseInvoicesTabs from "./pages/invoices/purchaseInvoice/PurchaseInvoicesTabs";
import FinancialRegisters from "./pages/financialregisters";
import FinancialRegisterAdd from "./pages/financialregisters/FinancialRegisterAdd";
import FinancialRegisterEdit from "./pages/financialregisters/FinancialRegisterEdit";
import PurchaseInvoiceDetail from "./pages/invoices/purchaseInvoice/PurchaseInvoiceDetail";
import AccountMovements from "./pages/accounts/AccountMovements";
import FinancialRegisterMovements from "./pages/financialregisters/FinancialRegisterMovements";
import OfferWitDemandEdit from "./pages/offers/OfferWitDemandEdit";
import PurchaseOfferDetail from "./pages/offers/purchaseOffers/PurchaseOfferDetail";
import PurchaseOrderDetail from "./pages/orders/purchaseOrders/PurchaseOrderDetail";
import SupplierMovements from "./pages/suppliers/SupplierMovements";
import CollectPaymentDetail from "./pages/accounts/CollectPaymentDetail";
import CollectPaymentSuppDetail from "./pages/suppliers/CollectPaymentSuppDetail";
import TemplateTaskTabs from "./pages/templateTasks";
import TaskTemplateAdd from "./pages/templateTasks/TaskTemplateAdd";
import TaskTemplateEdit from "./pages/templateTasks/TaskTemplateEdit";
import PurchaseEinvoiceAssociate from "./pages/orders/purchaseOrders/PurchaseEinvoiceAssociate";
import NumericSettingEdit from "./pages/settings/NumericSettingEdit";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/Userconfirm" element={<EmailConfirm />} />
          <Route path="/" element={<Login />} />
          <Route path="/registrationWizard" element={<Register />} />
          <Route
            path="/dashboards"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/demands"
            element={
              <PrivateRoute>
                <Demands />
              </PrivateRoute>
            }
          />
          <Route
            path="/demandDetail/:id"
            element={
              <PrivateRoute>
                <DemandDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/demandAdd"
            element={
              <PrivateRoute>
                <DemandAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/demandEdit/:id"
            element={
              <PrivateRoute>
                <DemandEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoices"
            element={
              <PrivateRoute>
                <Invoices />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseInvoice"
            element={
              <PrivateRoute>
                <PurchaseInvoicesTabs />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoiceDetail/:id"
            element={
              <PrivateRoute>
                <InvoiceDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoiceAdd"
            element={
              <PrivateRoute>
                <InvoiceAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseInvoiceAdd"
            element={
              <PrivateRoute>
                <PurchaseInvoiceAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoiceEdit/:id"
            element={
              <PrivateRoute>
                <InvoiceEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseInvoiceEdit/:id"
            element={
              <PrivateRoute>
                <PurchaseInvoiceEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseInvoiceDetail/:id"
            element={
              <PrivateRoute>
                <PurchaseInvoiceDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers"
            element={
              <PrivateRoute>
                <Offers />
              </PrivateRoute>
            }
          />
          <Route
            path="/offerEdit/:id"
            element={
              <PrivateRoute>
                <OfferEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/offerWithDemand/:id"
            element={
              <PrivateRoute>
                <OfferWithDemandAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/offerWithDemandEdit/:id"
            element={
              <PrivateRoute>
                <OfferWitDemandEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/offerAdd"
            element={
              <PrivateRoute>
                <OfferAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/offerDetail/:id"
            element={
              <PrivateRoute>
                <OfferDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseOffers"
            element={
              <PrivateRoute>
                <PurchaseOffersTabs />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseOfferAdd"
            element={
              <PrivateRoute>
                <PurchaseOfferAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseOfferEdit/:id"
            element={
              <PrivateRoute>
                <PurchaseOfferEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseOfferDetail/:id"
            element={
              <PrivateRoute>
                <PurchaseOfferDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/stockCards"
            element={
              <PrivateRoute>
                <StockCards />
              </PrivateRoute>
            }
          />
          <Route
            path="/stockCardsAdd"
            element={
              <PrivateRoute>
                <StockCardsAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/stockCardEdit/:id"
            element={
              <PrivateRoute>
                <StockCardsEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/stockCardsProducts/:id"
            element={
              <PrivateRoute>
                <StockCardsProducts />
              </PrivateRoute>
            }
          />
          <Route
            path="/accounts"
            element={
              <PrivateRoute>
                <Accounts />
              </PrivateRoute>
            }
          />
          <Route
            path="/accountAdd"
            element={
              <PrivateRoute>
                <AccountAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/accountEdit/:id"
            element={
              <PrivateRoute>
                <AccountEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/accountProducts/:id"
            element={
              <PrivateRoute>
                <AccountProducts />
              </PrivateRoute>
            }
          />
          <Route
            path="/accountMovements/:id"
            element={
              <PrivateRoute>
                <AccountMovements />
              </PrivateRoute>
            }
          />
          <Route
            path="/accountMovementDetail/:id"
            element={
              <PrivateRoute>
                <CollectPaymentDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path="/orderAdd"
            element={
              <PrivateRoute>
                <OrderAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/orderDetail/:id"
            element={
              <PrivateRoute>
                <OrderDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/orderEdit/:id"
            element={
              <PrivateRoute>
                <OrderEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseOrders"
            element={
              <PrivateRoute>
                <PurchaseOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseOrderAdd"
            element={
              <PrivateRoute>
                <PurchaseOrderAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseOrderEdit/:id"
            element={
              <PrivateRoute>
                <PurchaseOrderEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseOrderDetail/:id"
            element={
              <PrivateRoute>
                <PurchaseOrderDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseEinvoiceAssociate/:id"
            element={
              <PrivateRoute>
                <PurchaseEinvoiceAssociate />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/userAdd"
            element={
              <PrivateRoute>
                <UserAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/userEdit/:id"
            element={
              <PrivateRoute>
                <UserEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/userProducts/:id"
            element={
              <PrivateRoute>
                <UserProducts />
              </PrivateRoute>
            }
          />
          <Route
            path="/roles"
            element={
              <PrivateRoute>
                <Roles />
              </PrivateRoute>
            }
          />
          <Route
            path="/roleAdd"
            element={
              <PrivateRoute>
                <RoleAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/roleIdentify/:id"
            element={
              <PrivateRoute>
                <RoleIdentify />
              </PrivateRoute>
            }
          />
          <Route
            path="/workFlowList"
            element={
              <PrivateRoute>
                <WorkFlowList />
              </PrivateRoute>
            }
          />
          <Route
            path="/workFlow"
            element={
              <PrivateRoute>
                <WorkFlow />
              </PrivateRoute>
            }
          />
          <Route
            path="/workFlowEdit/:id"
            element={
              <PrivateRoute>
                <WorkFlowEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/workFlowAddForm"
            element={
              <PrivateRoute>
                <WorkFlowAddForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/formList"
            element={
              <PrivateRoute>
                <Forms />
              </PrivateRoute>
            }
          />
          <Route
            path="/formBuilder"
            element={
              <PrivateRoute>
                <FormBuilder />
              </PrivateRoute>
            }
          />
          <Route
            path="/formBuilderSchema"
            element={
              <PrivateRoute>
                <FormBuilderSchema />
              </PrivateRoute>
            }
          />
          <Route
            path="/formBuilderSchemaEdit/:id"
            element={
              <PrivateRoute>
                <FormBuilderSchemaEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/formBuilderSchemaDetail/:id"
            element={
              <PrivateRoute>
                <FormBuilderSchemaDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/tasks"
            element={
              <PrivateRoute>
                <Tasks />
              </PrivateRoute>
            }
          />
          <Route
            path="/taskAdd"
            element={
              <PrivateRoute>
                <TaskAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/taskDetailByAssignedUser/:id"
            element={
              <PrivateRoute>
                <TasksByAssignedUserDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/tasksByCreatorUserEdit/:id"
            element={
              <PrivateRoute>
                <TasksByCreatorUserEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/templatetasks"
            element={
              <PrivateRoute>
                <TemplateTaskTabs />
              </PrivateRoute>
            }
          />
          <Route
            path="/templateTaskEdit/:id"
            element={
              <PrivateRoute>
                <TaskTemplateEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/templateTaskAdd"
            element={
              <PrivateRoute>
                <TaskTemplateAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/projects"
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            }
          />
          <Route
            path="/projectAdd"
            element={
              <PrivateRoute>
                <ProjectAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/projectTaskAdd/:id"
            element={
              <PrivateRoute>
                <ProjectTaskAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/projectEdit/:id"
            element={
              <PrivateRoute>
                <ProjectEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/projectDetail/:id"
            element={
              <PrivateRoute>
                <ProjectDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/meetings"
            element={
              <PrivateRoute>
                <Meetings />
              </PrivateRoute>
            }
          />
          <Route
            path="/meetingAdd"
            element={
              <PrivateRoute>
                <MeetingAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/meetingEdit/:id"
            element={
              <PrivateRoute>
                <MeetingEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/meetingCheck/:id"
            element={
              <PrivateRoute>
                <MeetingCheck />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-meetings"
            element={
              <PrivateRoute>
                <MyMeetings />
              </PrivateRoute>
            }
          />
          <Route
            path="/meetingLogin/:id"
            element={
              <PrivateRoute>
                <MeetingLogin />
              </PrivateRoute>
            }
          />
          <Route
            path="/departments"
            element={
              <PrivateRoute>
                <Departments />
              </PrivateRoute>
            }
          />
          <Route
            path="/departmentAdd"
            element={
              <PrivateRoute>
                <DepartmentAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/departmentEdit/:id"
            element={
              <PrivateRoute>
                <DepartmentEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/departmentDetail/:id"
            element={
              <PrivateRoute>
                <DepartmentDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/formview/:id"
            element={
              <PrivateRoute>
                <FormView />
              </PrivateRoute>
            }
          />
          <Route
            path="/calendar"
            element={
              <PrivateRoute>
                <Calendars />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/numericSettingEdit/:id"
            element={
              <PrivateRoute>
                <NumericSettingEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/organizationSchema"
            element={
              <PrivateRoute>
                <OrganazationSchema />
              </PrivateRoute>
            }
          />
          <Route
            path="/stockCardBOM"
            element={
              <PrivateRoute>
                <StockCardBOM />
              </PrivateRoute>
            }
          />
          <Route
            path="/stockCardBomAdd"
            element={
              <PrivateRoute>
                <StockCardBomAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/stockCardBomEdit/:id"
            element={
              <PrivateRoute>
                <StockCardBomEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/machines"
            element={
              <PrivateRoute>
                <Machines />
              </PrivateRoute>
            }
          />
          <Route
            path="/machineAdd"
            element={
              <PrivateRoute>
                <MachineAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/machineEdit/:id"
            element={
              <PrivateRoute>
                <MachineEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/machineGroups"
            element={
              <PrivateRoute>
                <MachineGroups />
              </PrivateRoute>
            }
          />
          <Route
            path="/machineGroupAdd"
            element={
              <PrivateRoute>
                <MachineGroupAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/machineGroupEdit/:id"
            element={
              <PrivateRoute>
                <MachineGroupEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/operations"
            element={
              <PrivateRoute>
                <Operations />
              </PrivateRoute>
            }
          />
          <Route
            path="/operationAdd"
            element={
              <PrivateRoute>
                <OperationAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/operationEdit/:id"
            element={
              <PrivateRoute>
                <OperationEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/operationGroups"
            element={
              <PrivateRoute>
                <OperationGroups />
              </PrivateRoute>
            }
          />
          <Route
            path="/operationGroupAdd"
            element={
              <PrivateRoute>
                <OperationGroupAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/operationGroupEdit/:id"
            element={
              <PrivateRoute>
                <OperationGroupEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/personels"
            element={
              <PrivateRoute>
                <Personels />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelAdd"
            element={
              <PrivateRoute>
                <PersonelAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelEdit/:id"
            element={
              <PrivateRoute>
                <PersonelEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelDetail/:id"
            element={
              <PrivateRoute>
                <PersonelDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelWageAdd/:id"
            element={
              <PrivateRoute>
                <PersonelWageAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelWageEdit/:id"
            element={
              <PrivateRoute>
                <PersonelWageEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelWages/:id"
            element={
              <PrivateRoute>
                <PersonelWages />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelGroups"
            element={
              <PrivateRoute>
                <PersonelGroups />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelGroupAdd"
            element={
              <PrivateRoute>
                <PersonelGroupAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelGroupEdit/:id"
            element={
              <PrivateRoute>
                <PersonelGroupEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelGroupDetail/:id"
            element={
              <PrivateRoute>
                <PersonelGroupDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelShifts"
            element={
              <PrivateRoute>
                <PersonelShifts />
              </PrivateRoute>
            }
          />
          <Route
            path="/personelShiftAdd"
            element={
              <PrivateRoute>
                <PersonelShiftAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/personsOvertimeAdd"
            element={
              <PrivateRoute>
                <PersonsOvertimeAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/generalShiftAdd"
            element={
              <PrivateRoute>
                <GeneralShiftAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/shiftDetails/:id"
            element={
              <PrivateRoute>
                <ShiftDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/workOrders"
            element={
              <PrivateRoute>
                <WorkOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/workOrderAdd"
            element={
              <PrivateRoute>
                <WorkOrderAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/preProductionAdd"
            element={
              <PrivateRoute>
                <PreProductionAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/manualWorkOrderAdd"
            element={
              <PrivateRoute>
                <ManualWorkOrderAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/workOrderClosing/:id"
            element={
              <PrivateRoute>
                <WorkOrderClosing />
              </PrivateRoute>
            }
          />
          <Route
            path="/workOrderDetail/:id"
            element={
              <PrivateRoute>
                <WorkOrderDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/workOrderClosedDetail/:id"
            element={
              <PrivateRoute>
                <WorkOrderClosedDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/wareHouses"
            element={
              <PrivateRoute>
                <WareHouses />
              </PrivateRoute>
            }
          />
          <Route
            path="/wareHouseAdd"
            element={
              <PrivateRoute>
                <WareHouseAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/wareHouseEdit/:id"
            element={
              <PrivateRoute>
                <WareHouseEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/shippingSlips"
            element={
              <PrivateRoute>
                <ShippingSlips />
              </PrivateRoute>
            }
          />
          <Route
            path="/shippingSlipAdd"
            element={
              <PrivateRoute>
                <ShippingSlipAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/wareHouseSlipEdit/:id"
            element={
              <PrivateRoute>
                <WareHouseSlipEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/qualityFormsCheck/:id"
            element={
              <PrivateRoute>
                <QualityFormsCheck />
              </PrivateRoute>
            }
          />
          <Route
            path="/wayBills"
            element={
              <PrivateRoute>
                <WayBills />
              </PrivateRoute>
            }
          />
          <Route
            path="/salesWaybills"
            element={
              <PrivateRoute>
                <SalesWaybills />
              </PrivateRoute>
            }
          />
          <Route
            path="/salesWaybillAdd"
            element={
              <PrivateRoute>
                <SalesWaybillAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/salesWayBillEdit/:id"
            element={
              <PrivateRoute>
                <SalesWaybillEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/wayBillAdd"
            element={
              <PrivateRoute>
                <WayBillAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/wayBillDetail/:id"
            element={
              <PrivateRoute>
                <WayBillDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/wayBillEdit/:id"
            element={
              <PrivateRoute>
                <WayBillEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/shipping"
            element={
              <PrivateRoute>
                <Shipping />
              </PrivateRoute>
            }
          />
          <Route
            path="/shippingAdd"
            element={
              <PrivateRoute>
                <ShippingAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/shippingOrderAdd"
            element={
              <PrivateRoute>
                <ShippingOrderAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/shippingEdit/:id"
            element={
              <PrivateRoute>
                <ShippingEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/shippingDetail/:id"
            element={
              <PrivateRoute>
                <ShippingDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/suppliers"
            element={
              <PrivateRoute>
                <Suppliers />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplierAdd"
            element={
              <PrivateRoute>
                <SupplierAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplierEdit/:id"
            element={
              <PrivateRoute>
                <SupplierEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/suppliersMovements/:id"
            element={
              <PrivateRoute>
                <SupplierMovements />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplierMovementDetail/:id"
            element={
              <PrivateRoute>
                <CollectPaymentSuppDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/potentialAccounts"
            element={
              <PrivateRoute>
                <PotentialAccounts />
              </PrivateRoute>
            }
          />
          <Route
            path="/potentialAccountAdd"
            element={
              <PrivateRoute>
                <PotentialAccountAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/potentialAccountEdit/:id"
            element={
              <PrivateRoute>
                <PotentialAccountEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/opportunities"
            element={
              <PrivateRoute>
                <Opportunities />
              </PrivateRoute>
            }
          />
          <Route
            path="/opportunityAdd"
            element={
              <PrivateRoute>
                <OpportunityAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/opportunityEdit/:id"
            element={
              <PrivateRoute>
                <OpportunityEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/financialregisters"
            element={
              <PrivateRoute>
                <FinancialRegisters />
              </PrivateRoute>
            }
          />
          <Route
            path="/financialRegisterAdd"
            element={
              <PrivateRoute>
                <FinancialRegisterAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/financialRegisterEdit/:id"
            element={
              <PrivateRoute>
                <FinancialRegisterEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/financialRegisterMovements/:id"
            element={
              <PrivateRoute>
                <FinancialRegisterMovements />
              </PrivateRoute>
            }
          />
          <Route path="/deneme" element={<Deneme />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
