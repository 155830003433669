import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import { useTranslation } from "react-i18next";
import { FaRegFilePdf } from "react-icons/fa6";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CollectPaymentDetailPDF from "./CollectPaymentDetailPDF";

const CollectPaymentDetailModal = ({ onCancel = () => {}, id }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [logoStr, setLogoStr] = useState(null);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(Number());

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(`/api/Customer/GetCustomerLogo?id=${user.country}`)
        .then((res) => {
          setLogoStr(res.data);
        })
        .catch((err) => {
          console.log(err.data);
        });

      api
        .get(
          `/api/Account/GetAccountCredit?id=${id}&customerID=${user.country}`
        )
        .then((res) => setData(res.data))
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
          <div className="w-full p-12 flex flex-col gap-7 ">
            <div className="flex justify-between">
              <h1 className="text-4xl font-bold leading-none tracking-tight text-[#050a27] ">
                Cari Hareket Detayı
              </h1>
              <div className="pr-5">
                <PDFDownloadLink
                  document={
                    <CollectPaymentDetailPDF logoStr={logoStr} data={data} />
                  }
                  fileName={`${data.accountName || "FORM"} Cari Hareketleri.pdf`}
                >
                  {({ loading }) =>
                    loading ? (
                      <a className="text-lg font-medium text-red-600 hover:underline">
                        Yükleniyor...
                      </a>
                    ) : (
                      <a className="text-lg font-medium text-red-600 hover:underline flex gap-2">
                        <FaRegFilePdf size={30} />
                        Çıktı al.
                      </a>
                    )
                  }
                </PDFDownloadLink>
              </div>
            </div>

            <div className="bg-[#050A27] w-full overflow-hidden">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-3xl font-semibold text-white">
                  {data?.registerName}
                </h3>
                <p className="mt-1 max-w-2xl text-base font-medium text-gray-400">
                  {data?.accountName}
                </p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <label
                      htmlFor="userSelect"
                      className="text-sm font-medium text-gray-500"
                    >
                      Borç :
                    </label>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data?.borc}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Alacak :
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data?.alacak}
                    </dd>
                  </div>
                  <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Ödeme Tipi :
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data.typeString ? data.typeString : "Belirtilmemiş"}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Açıklama :
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data?.description}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="col-span-2 flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-56"
              >
                Kapat
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectPaymentDetailModal;
