import React, { useEffect, useState } from "react";
import "../demands/demands.css";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { TbEdit } from "react-icons/tb";

const TaskMainTemplate = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState("");
  const [token, setToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=2046`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Task/GetTaskMainTemplates?customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize]);

  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (columnName === "name") {
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            sortOrderCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };

  const handleDelete = async (templateID) => {
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Task/DeleteTaskMainTemplate?templateID=${templateID}&customerID=${user.country}`
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.templateID !== templateID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Şablon görev kategorisi silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Kategori silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  const openPostModal = () => {
    Swal.fire({
      title: "Şablon Görev Kategorisi ekle",
      input: "textarea",
      inputPlaceholder: "Kategori adını buraya yazın...",
      showCancelButton: true,
      confirmButtonText: "Ekle",
      cancelButtonText: "İptal",
      inputValidator: (value) => {
        if (!value) {
          return "Lütfen bir kategori adı yazın!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        handleAddTaskMain(result.value);
      }
    });
  };

  const handleAddTaskMain = async (value) => {
    setLoading(true);

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!token) {
      Swal.fire("Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const postObject = {
      customerID,
      name: value,
    };

    try {
      const res = await api.post(`/api/Task/PostTaskMainTemplate`, postObject, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Şablon görev kategorisi oluşturuldu",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  //PUT
  const openEditModal = async (templateID) => {
    setLoading(true);

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!token) {
      Swal.fire("Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    try {
      const { data } = await api.get(`/api/Task/GetTaskMainTemplate`, {
        params: { id: templateID, customerID },
        headers: { Authorization: `Bearer ${token}` },
      });

      // Güncelleme modalını aç
      Swal.fire({
        title: "Şablon Görev Kategorisini Güncelle",
        input: "textarea",
        inputValue: data.name, // Mevcut kategori adını inputa ekle
        inputPlaceholder: "Kategori adını buraya yazın...",
        showCancelButton: true,
        confirmButtonText: "Güncelle",
        cancelButtonText: "İptal",
        inputValidator: (value) => {
          if (!value) {
            return "Lütfen bir kategori adı yazın!";
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed && result.value) {
          await handleUpdateTaskMain(templateID, result.value);
        }
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Veri alınırken hata oluştu!",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTaskMain = async (templateID, updatedName) => {
    setLoading(true);

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!token) {
      Swal.fire("Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const putObject = {
      templateID,
      customerID,
      name: updatedName,
    };

    try {
      await api.put(`/api/Task/PutTaskMainTemplate`, putObject, {
        headers: { Authorization: `Bearer ${token}` },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Şablon görev kategorisi güncellendi",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Güncelleme sırasında hata oluştu!",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          <div className="add-button pt-6">
            {permissions.insertPermission && (
              <button
                type="button"
                className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-[22rem]"
                title="Şablon Görev Kategorisi Oluştur"
                onClick={() => openPostModal()}
              >
                Şablon Görev Kategorisi Oluştur
                <i className="fa-solid fa-plus ml-2"></i>
              </button>
            )}
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title">
                Şablon Görev Kategorileri
              </h1>
            </div>
            <div className="flex justify-between pt-2">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="Kategori ara.."
                value={searchTerm}
              />
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    Şablon Görev Kategori Adı{" "}
                    {sortOrder.name === "asc" ? "▲" : "▼"}
                  </th>

                  <th scope="col" className="px-6 py-3">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data
                    ?.filter((item) =>
                      item.name
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => (
                      <tr
                        key={index}
                        className={`bg-white border-b hover:bg-gray-50`}
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          title={item.name}
                        >
                          {item.name}
                        </th>

                        <td className="px-3 py-4">
                          <div className="flex items-center space-x-3">
                            {permissions.updatePermission && (
                              <button
                                onClick={() => {
                                  handleDelete(item.templateID);
                                }}
                              >
                                <a
                                  title="Şablon Görev Kategorisini düzenle"
                                  className="font-medium text-[#050A27]  hover:underline"
                                  onClick={() => openEditModal(item.templateID)}
                                >
                                  <TbEdit
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={24}
                                  />
                                </a>
                              </button>
                            )}
                            {permissions.deletePermission && (
                              <button
                                onClick={() => {
                                  handleDelete(item.templateID);
                                }}
                              >
                                <a
                                  title="Şablon Görev Kategorisini sil"
                                  className="font-medium text-[#050A27]  hover:underline"
                                >
                                  <RiDeleteBin6Line
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={24}
                                  />
                                </a>
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <nav
            className="mt-2 float-right my-5"
            aria-label="Page navigation example"
          >
            <ul className="flex items-center -space-x-px h-10 text-sm">
              <li>
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className={`flex items-center justify-center px-4 h-10 ${
                    currentPage === 1
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                </button>
              </li>
              {renderPageNumbers()}
              <li>
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  className={`flex items-center justify-center pl-4 h-10 ${
                    currentPage === totalPages
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default TaskMainTemplate;
