import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TaskTransferModal from "./TaskTransferModal";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";

const PeriodicTasks = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [matchedData, setMatchedData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [openMdule, setOpenModule] = useState(false);
  const [selectedTaskID, setSelectedTaskID] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=13`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(
              `/api/Task/GetPeriodicTasks?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => setData(res.data.items))
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/Project/GetProjects?customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setProjects(res.data.items);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  useEffect(() => {
    if (data && projects) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data.map((item) => {
        const matchedProject = projects.find(
          (project) => project.projectID === item.projectID
        );
        if (matchedProject) {
          return {
            ...item,
            projectName: matchedProject.name,
          };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setMatchedData(matchedUsers);
    }
  }, [data, projects]);


  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  function getPriorityFlag(priorityLevel) {
    switch (priorityLevel) {
      case 5:
        return <i className="fa-solid fa-flag text-red-600 fa-xl"></i>; // Kırmızı Bayrak
      case 4:
        return <i className="fa-solid fa-flag text-orange-400 fa-xl"></i>; // Turuncu Bayrak
      case 3:
        return <i className="fa-solid fa-flag text-yellow-400 fa-xl"></i>; // Sarı Bayrak
      case 2:
        return <i className="fa-solid fa-flag text-green-600 fa-xl"></i>; // Yeşil Bayrak
      case 1:
        return <i className="fa-solid fa-flag text-blue-600 fa-xl"></i>; // Mavi Bayrak
      default:
        return <i className="fa-solid fa-flag text-gray-600 fa-xl"></i>; // Varsayılan renk
    }
  }

  
  const getEndDateStatus = (endDate, status) => {
    const today = new Date();
    const endTaskDate = new Date(endDate);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // Yarın

    if (status === 1522) {
      return <p className="text-green-600 font-medium">Tamamlandı</p>;
    } else if (status === 72) {
      return <p className="text-red-600 font-medium">İptal Edildi</p>;
    } else if (status === 73) {
      return <p className="text-yellow-600 font-medium">Onay Bekliyor</p>;
    } else if (endTaskDate.toDateString() === tomorrow.toDateString()) {
      return <p className="text-yellow-500 font-medium">Süre Dolmak Üzere</p>;
    } else if (endTaskDate > today) {
      return <p className="text-blue-600 font-medium">Devam Ediyor</p>;
    } else {
      return <p className="text-red-600 font-medium">Süre Doldu</p>;
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleDelete = async (taskID, customerID) => {
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/api/Task/DeleteTask/${taskID}?customerID=${customerID}`)
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.taskID !== taskID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Görev silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Görev silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  console.log(matchedData);

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="w-full mt-7">
          <div className="add-button">
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title ">
                Periyodik Görevler
              </h1>
            </div>
            {permissions.insertPermission && (
              <Link to={"/taskAdd"}>
                <button
                  type="button"
                  className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-72"
                >
                  Görev Oluştur
                  <i className="fa-solid fa-plus ml-2"></i>
                </button>
              </Link>
            )}
            <div className="flex justify-between">
              <input
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="Görev ara.."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                  <th scope="col" className="px-3 py-3"></th>
                  <th scope="col" className="px-3 py-3">
                    Görev Başlığı
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Görev Adı
                  </th>

                  <th scope="col" className="px-3 py-3">
                    Başlangıç Tarih
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Bitiş Tarih
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Atayan
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Proje
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Zaman
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Durum
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Tamamlanma Durumu
                  </th>
                  <th scope="col" className="px-3 py-3">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  matchedData
                    ?.filter((item) =>
                      item.name
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => {
                      const rowClass =
                        item.status === 1522 ? "bg-gray-50" : "bg-white";
                      const rowText =
                        item.status === 1522 || item.status === 72
                          ? "line-through"
                          : "";
                      const highPriorityColor = item.highPriority
                        ? "bg-yellow-50 hover:bg-yellow-100"
                        : "bg-white hover:bg-gray-50";

                      return (
                        <tr
                          key={index}
                          className={`border-b  ${rowClass} ${highPriorityColor}`}
                        >
                          <td className="px-3 py-4">
                            {getPriorityFlag(item?.priorityLevel)}
                          </td>
                          <th
                            scope="row"
                            className={`px-3 py-4 font-medium text-gray-900 whitespace-nowrap ${rowText}`}
                            title={item.name}
                          >
                            {truncateText(item.name, 20)}
                          </th>
                          <td
                            title={item.title}
                            className={`px-3 py-4 ${rowText}`}
                          >
                            {truncateText(item.title, 25)}
                          </td>

                          <td className="px-3 py-4">
                            {formatDate(item.startDate)}
                          </td>
                          <td className="px-3 py-4">
                            {formatDate(item.endDate)}
                          </td>
                          <td className="px-3 py-4">
                            {item?.creatorUser?.userName}
                          </td>
                          <td className="px-3 py-4">{item.projectName}</td>

                          <td className="px-3 py-4">
                            {item.periodicType === 1 && "Günlük"}
                            {item.periodicType === 2 && "Haftalık"}
                            {item.periodicType === 3 && "Aylık"}
                            {item.periodicType === 4 && "Yıllık"}
                          </td>

                          <td className="px-3 py-4">
                            {getEndDateStatus(item.endDate, item.status)}
                          </td>
                          <td className="px-3 py-4">
                            <div className="w-full bg-gray-200 rounded-lg h-2.5 ">
                              <div
                                className="bg-slate-600 h-2.5 rounded-lg"
                                style={{ width: `${item.intensityPercent}%` }}
                              ></div>
                            </div>
                          </td>
                          <td className="flex items-center px-3 py-4 space-x-4">
                            <Link to={`/tasksByCreatorUserEdit/${item.taskID}`}>
                              <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                <TbEdit
                                  className="hover:scale-110 transition-transform duration-200"
                                  size={24}
                                />
                              </a>
                            </Link>
                            <button
                              onClick={() => {
                                handleDelete(item.taskID, item.customerID);
                              }}
                            >
                              <a className="font-medium text-[#050A27] hover:underline">
                                <RiDeleteBin6Line
                                  className="hover:scale-110 transition-transform duration-200"
                                  size={24}
                                />
                              </a>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td
                      colSpan={11}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <nav
            className="mt-2 float-right my-5"
            aria-label="Page navigation example"
          >
            <ul className="flex items-center -space-x-px h-10 text-sm">
              <li>
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className={`flex items-center justify-center px-4 h-10 ${
                    currentPage === 1
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                </button>
              </li>
              {renderPageNumbers()}
              <li>
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  className={`flex items-center justify-center pl-4 h-10 ${
                    currentPage === totalPages
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {openMdule === true && (
        <TaskTransferModal
          onCancel={() => setOpenModule(false)}
          taskID={selectedTaskID}
        />
      )}
    </>
  );
};

export default PeriodicTasks;
