import React, { useEffect, useState } from "react";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { TbEdit } from "react-icons/tb";
import { FaRegEye } from "react-icons/fa";

const TemplateTasks = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [allData, setAllData] = useState(null);
  const [users, setUsers] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [matchedData, setMatchedData] = useState(null);
  const [matchedAllData, setMatchedAllData] = useState(null);
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=2046`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Project/GetProjects?customerID=${user.country}&pageNumber=1&pageSize=99999`
            )
            .then((res) => setProjects(res.data.items))
            .catch((err) => console.log(err.data));

          api
            .get(`/api/User/GetUsers?customerID=${user.country}`)
            .then((res) => setUsers(res.data))
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Task/GetTemplateTasks?customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  useEffect(() => {
    if (data && projects) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data.map((item) => {
        const matchedProject = projects.find(
          (project) => project.projectID === item.projectID
        );
        if (matchedProject) {
          return {
            ...item,
            projectName: matchedProject.name,
          };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setMatchedData(matchedUsers);
    }
  }, [data, projects]);

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (columnName === "name") {
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            sortOrderCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };

  const handleDelete = async (taskID, customerID) => {
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Task/DeleteTaskTemplate?taskID=${taskID}&customerID=${customerID}`
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.taskID !== taskID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Görev silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Görev silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  function getPriorityFlagClass(highPriority) {
    if (highPriority) {
      return <i className="fa-solid fa-check fa-xl text-green-600"></i>; // Kırmızı Bayrak
    } else {
      return null;
    }
  }

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          <div className="add-button pt-6">
            {permissions.insertPermission && (
              <Link to={"/templateTaskAdd"}>
                <button
                  type="button"
                  className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12 w-[22rem]"
                  title="Şablon görev oluştur"
                >
                  Şablon Görev Oluştur
                  <i className="fa-solid fa-plus ml-2"></i>
                </button>
              </Link>
            )}
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title">Şablon Görevler</h1>
            </div>
            <div className="flex justify-between pt-2">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="Şablon görev ara.."
                value={searchTerm}
              />
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                  <th scope="col" className="px-3 py-3">
                    Görev Başlığı
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Görev Adı
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Yüksek Öncelikli
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Proje
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Tamamlanma Durumu
                  </th>
                  <th scope="col" className="px-3 py-3">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {matchedData?.length > 0 ? (
                  matchedData
                    ?.filter((item) =>
                      item.name
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => {
                      const rowClass =
                        item.status === 1522 ? "bg-gray-50" : "bg-white";
                      const rowText =
                        item.status === 1522 ? "line-through" : "";
                      const highPriorityColor = item.highPriority
                        ? "bg-yellow-50 hover:bg-yellow-100"
                        : "bg-white hover:bg-gray-50";
                      return (
                        <tr
                          key={index}
                          className={`border-b hover:bg-gray-50 ${rowClass} ${highPriorityColor}`}
                        >
                          <th
                            scope="row"
                            className={`px-3 py-4 font-medium text-gray-900 whitespace-nowrap ${rowText}`}
                            title={item.name}
                          >
                            {truncateText(item.name, 20)}
                          </th>
                          <td
                            title={item.title}
                            className={`px-3 py-4 ${rowText}`}
                          >
                            {truncateText(item.title, 25)}
                          </td>
                          <td className="px-3 py-4 text-center">
                            {getPriorityFlagClass(item.highPriority)}
                          </td>
                          <td className="px-3 py-4">{item.projectName}</td>

                          <td className="px-3 py-4">
                            <div className="w-full bg-gray-200 rounded-lg h-2.5 ">
                              <div
                                className="bg-slate-600 h-2.5 rounded-lg"
                                style={{ width: `${item.intensityPercent}%` }}
                              ></div>
                            </div>
                          </td>
                          <td className="flex items-center px-3 py-4 space-x-4">
                            {permissions.updatePermission && (
                              <Link to={`/templateTaskEdit/${item.taskID}`}>
                                <a
                                  title="Görev düzenle"
                                  className="font-medium text-[#050A27]  hover:underline"
                                >
                                  <TbEdit
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={24}
                                  />
                                </a>
                              </Link>
                            )}
                            {permissions.deletePermission && (
                              <button>
                                <a
                                  title="Görev sil"
                                  className="font-medium text-[#050A27]  hover:underline"
                                  onClick={() => {
                                    handleDelete(item.taskID, item.customerID);
                                  }}
                                >
                                  <RiDeleteBin6Line
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={24}
                                  />
                                </a>
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default TemplateTasks;
