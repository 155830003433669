import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const CollectPaymentDetailPDF = ({ logoStr, data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontFamily: "Roboto",
      backgroundColor: "#f7f7f7", // Background color for the page
      position: "relative", // Make the page container relative for positioning
    },
    section: {
      marginBottom: 15,
      marginTop: 50
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 20,
    },
    logoContainer: {
      width: "30%",
    },
    logo: {
      width: "100%",
      maxWidth: 140,
      height: "auto",
    },
    title: {
      fontSize: 26, // Increased font size for the title
      fontWeight: "900", // Made the title bold
      marginBottom: 10,
      color: "#333", // Darker color for titles
      textAlign: "center",
    },
    subtitle: {
      fontSize: 18, // Increased font size for subtitle
      color: "#555",
      textAlign: "center",
    },
    text: {
      fontSize: 14, // Increased font size for body text
      color: "#333",
      marginBottom: 5,
    },
    boldText: {
      fontSize: 14, // Increased font size for bold text
      fontWeight: "bold", // Made text bold
      color: "#333",
    },
    table: {
      width: "100%",
      borderTopWidth: 1,
      borderTopColor: "#ddd",
      borderTopStyle: "solid",
      marginTop: 15,
    },
    tableHeader: {
      flexDirection: "row",
      backgroundColor: "#e0e0e0",
      padding: 8,
    },
    tableHeaderCell: {
      flex: 1,
      fontSize: 12, // Increased font size for table header
      fontWeight: "bold",
      color: "#333",
      textAlign: "center",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
      paddingVertical: 8,
    },
    tableCell: {
      flex: 1,
      fontSize: 12, // Increased font size for table cells
      color: "#333",
      textAlign: "center",
      padding: 5,
    },
    footer: {
      position: "absolute", // Absolute position to place it at the bottom
      bottom: 30, // Distance from the bottom of the page
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 14, // Increased font size for footer
      color: "#555",
    },
  });

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            {logoStr ? (
              <Image src={logoStr} style={styles.logo} />
            ) : (
              <Text>LOGO</Text>
            )}
          </View>
          <View>
            <Text style={styles.title}>{data?.registerName}</Text>
            <Text style={styles.subtitle}>{data?.accountName}</Text>
          </View>
        </View>

        {/* Payment Details */}
        <View style={styles.section}>
          <Text style={styles.boldText}>Borç: {data?.borc}</Text>
          <Text style={styles.text}>Alacak: {data?.alacak}</Text>
          <Text style={styles.text}>Ödeme Tipi: {data?.typeString || "Belirtilmemiş"}</Text>
          <Text style={styles.text}>Açıklama: {data?.description}</Text>
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <Text>Ödeme Detayı</Text>
        </View>
      </Page>
    </Document>
  );
};

export default CollectPaymentDetailPDF;
