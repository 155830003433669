import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import api from "../../api/api";
import Swal from "sweetalert2";
import LoadingSpinnerModal from "../LoadingSpinnerModal";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const CollectPaymentSuppModal = ({ onCancel = () => {}, id }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [registers, setRegisters] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState({value:0, label:"Ödeme tipini seçiniz..."});
  const [selectedRegister, setSelectedRegister] = useState({
    value: 0,
    label: "Kasa seçiniz...",
  });
  const [invoicesByUnPaid, setInvoicesByUnPaid] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({
    value: 0,
    label: "Fatura seçiniz...",
  });
  const [alacak, setAlacak] = useState(Number());
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(Number());
  const [accountCredit, setAccountCredit] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(
          `/api/Invoice/GetPurchaseInvoicesByUnPaid?customerID=${user.country}`
        )
        .then((res) => {
          const newData = res.data?.map((item) => ({
            value: item.invoiceID,
            label: item.name,
            totalPrice: item.totalPrice,
          }));
          setInvoicesByUnPaid(newData);
        })
        .catch((err) => {
          console.log(err.data);
        });

        api
        .get("/api/Common/GetParameters?parametername=AccountCreditType")
        .then((res) => {
          const newData = res.data?.map((item) => ({
            label: item.parameterValue2,
            value: item.parameterID,
          }));
          setTypes(newData);
        })
        .catch((err) => {
          console.log(err.data);
        });

      api
        .get(
          `/api/Account/GetAccountCreditByAccount?id=${id}&customerID=${user.country}`
        )
        .then((creditRes) => {
          setAccountCredit(creditRes.data);
        })
        .catch((creditErr) => {
          console.log(creditErr.data);
        });

      api
        .get(
          `/api/Register/GetRegisters?customerID=${user.country}&typeID=0&pageNumber=1&pageSize=99999`
        )
        .then((res) => {
          const newData = res.data?.items.map((item) => ({
            value: item.registerID,
            label: item.name,
          }));
          setRegisters(newData);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user]);

  const handleAccountCredit = async () => {
    setLoading(true);

    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!token) {
      Swal.fire("Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const creditObject = {
      customerID,
      accountID: id,
      registerID: selectedRegister?.value,
      invoiceID: selectedInvoice?.value,
      alacak,
      type: selectedType?.value,
      description: "",
    };

    try {
      const res = await api.post(
        `/api/Account/PostAccountCreditBorcOdeme/PostAccountCreditBorcOdeme`,
        creditObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Ödeme yapıldı!",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-50 flex justify-center items-center">
      <div className="w-3/5 p-8 bg-white rounded-lg flex flex-col justify-center items-center">
        {loading ? (
          <LoadingSpinnerModal loading={loading} />
        ) : (
          <form
            onSubmit={handleAccountCredit}
            className="grid grid-cols-2 gap-2 items-center"
          >
            <div className="col-span-2">
              <h1 className="text-center  text-4xl font-bold leading-none tracking-tight text-blue-800 pb-10">
                Ödeme Yap
              </h1>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Faturalar :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                options={invoicesByUnPaid}
                value={selectedInvoice}
                onChange={(e) => {
                  setSelectedInvoice(e);
                  setAlacak(e.totalPrice);
                }}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Kasalar :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                options={registers}
                value={selectedRegister}
                onChange={(e) => setSelectedRegister(e)}
                isDisabled={selectedInvoice?.totalPrice * -1 < accountCredit.total}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Ödeme Tipi :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                options={types}
                value={selectedType}
                onChange={(e) => setSelectedType(e)}
                isDisabled={selectedInvoice?.totalPrice * -1 < accountCredit.total}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Tutar :
              </label>
              <input
                type="number"
                placeholder="Tutar giriniz..."
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={alacak}
                onChange={(e) => setAlacak(e.target.value)}
                disabled={selectedInvoice?.totalPrice * -1 < accountCredit.total}
              />
            </div>

            <div className="col-span-2 flex justify-center gap-[20px]">
              <button
                onClick={onCancel}
                className="bg-[#B10F0F] hover:bg-[#b10f0fd8] text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32"
              >
                Vazgeç
              </button>
              <button
                type="submit"
                className={`text-white font-medium py-2 px-4 rounded-lg m-5 h-12 w-32 ${
                    selectedInvoice?.totalPrice * -1 < accountCredit.total ||
                  selectedInvoice.value === 0
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-[#0C692F] hover:bg-[#0c6930d3]"
                }`}
                disabled={
                    selectedInvoice?.totalPrice * -1 < accountCredit.total ||
                  selectedInvoice.value === 0
                }
              >
                Gönder
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CollectPaymentSuppModal;
