import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

// Font ayarları
// Register Font
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

// Stil ayarları
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 15,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "black",
    fontFamily: "Roboto",
  },
  table: {
    display: "table",
    width: "auto",
    margin: 12,
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "20%", // Adjust the width as needed
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "black",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: "Roboto",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Roboto",
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 170, // Adjust width and height as needed
    height: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 140, // Adjust width and height to fit your logo
    height: 40,
  },
});

const TasksByAssignedUserPDF = ({logoStr,pdfData, data }) => {
  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const getEndDateStatus = (endDate, status) => {
    const today = new Date();
    const endTaskDate = new Date(endDate);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // Yarın
  
    let statusText = "";
    let color = "black";
  
    if (status === 1522) {
      statusText = "Tamamlandı";
      color = "green";
    } else if (status === 72) {
      statusText = "İptal Edildi";
      color = "red";
    } else if (status === 73) {
      statusText = "Onay Bekliyor";
      color = "orange";
    } else if (endTaskDate.toDateString() === tomorrow.toDateString()) {
      statusText = "Süre Dolmak Üzere";
      color = "yellow";
    } else if (endTaskDate > today) {
      statusText = "Devam Ediyor";
      color = "blue";
    } else {
      statusText = "Süre Doldu";
      color = "red";
    }
  
    return <Text style={{ color }}>{statusText}</Text>;
  };
  
  return (
    <Document>
      <Page style={styles.body}>
      <View style={styles.logoContainer}>
          {logoStr && <Image src={logoStr} style={styles.logo} />}
        </View>
        <Text style={styles.header} fixed>
          Atanmış Görevler
        </Text>
        <Text style={styles.header} fixed>Belge No: {pdfData}</Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Görev Başlığı</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Yüksek Öncelikli</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Baş. Tarih</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Bit. Tarih</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Atayan</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Proje</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Durum</Text>
            </View>
          </View>

          {data?.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{truncateText(item.name, 20)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.highPriority ? "Evet" : "Hayır"}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{formatDate(item.startDate)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{formatDate(item.endDate)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.creatorUser.userName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {item.projectName}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {getEndDateStatus(item.endDate, item.status)}
                </Text>
              </View>
            </View>
          ))}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default TasksByAssignedUserPDF;
