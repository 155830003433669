import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Breadcrumb from "../../components/bars/Breadcrumb";
import Sidebars from "../../components/bars/Sidebar";
import Header from "../../components/bars/Header";

const NumericSettingEdit = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const [customerID, setCustomerID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [includeYear, setIncludeYear] = useState(false);
  const [includeMonth, setIncludeMonth] = useState(false);
  const [includeDay, setIncludeDay] = useState(false);
  const [serialLength, setSerialLength] = useState({});
  const [resetPeriod, setResetPeriod] = useState({});
  const [prefix, setPrefix] = useState("");
  const [subPrefix, setSubPrefix] = useState("");

  useEffect(() => {
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=21`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
          .get(`/api/Common/GetConfigNumber?customerID=${user.country}&id=${params.id}`)
          .then((res) => {
            setData(res.data);
            setPrefix(res.data.prefix);
            setSubPrefix(res.data.subPrefix);
            setIncludeYear(res.data.includeYear);
            setIncludeMonth(res.data.includeMonth);
            setSerialLength({
              value: res.data.serialLength,
              label: res.data.serialLength,
            });
            setIncludeDay(res.data.includeDay);
        
            const resetPeriodMapping = {
              D: { value: "D", label: "Gün" },
              M: { value: "M", label: "Ay" },
              Y: { value: "Y", label: "Yıl" },
            };
        
            setResetPeriod(resetPeriodMapping[res.data.resetPeriod] || null);
          })
          .catch((err) => console.error(err.data))
          .finally(() => setLoading(false));
        
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const postData = {
    configID: params.id,
    customerID,
    prefix,
    subPrefix,
    includeYear,
    includeMonth,
    includeDay,
    serialLength: serialLength?.value,
    resetPeriod: resetPeriod?.value,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .put("/api/Common/PutConfigNumber/PutConfigNumber", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Numaralandırma ayarları güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      })

      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => setLoading(false));
  };

  console.log(data);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Ayarlar", href: "/settings" },
                { label: "Numaralandırma Ayarı Güncelleme", href: "" },
              ]}
            />

            <div className="m-12 min-h-screen">
              <div className="add-button">
                <div className="pb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">
                    Numaralandırma Ayarı Güncelleme
                  </h1>
                </div>
              </div>
              <div className="p-12 mx-auto  rounded-[3px] min-h-screen w-full bg-gray-100">
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-2 gap-7 items-center w-full"
                >
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı :
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={data?.name}
                      disabled
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="prefix"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Ön Ek :
                    </label>
                    <input
                      type="text"
                      id="prefix"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={prefix}
                      onChange={(e) => setPrefix(e.target.value)}
                      maxLength={5}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="subPrefix"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Alt Ek :
                    </label>
                    <input
                      type="text"
                      id="subPrefix"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={subPrefix}
                      onChange={(e) => setSubPrefix(e.target.value)}
                      maxLength={6}
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Seri Numara Uzunluğu
                    </label>
                    <Select
                      maxMenuHeight={200}
                      onChange={(e) => setSerialLength(e)}
                      value={serialLength}
                      options={[
                        { value: 1, label: 1 },
                        { value: 2, label: 2 },
                        { value: 3, label: 3 },
                        { value: 4, label: 4 },
                        { value: 5, label: 5 },
                      ]}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Seri Numarası Yenileme
                    </label>
                    <Select
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                      value={resetPeriod}
                      onChange={(e) => setResetPeriod(e)}
                      options={[
                        { value: "D", label: "Gün" },
                        { value: "M", label: "Ay" },
                        { value: "Y", label: "Yıl" },
                      ]}
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <label
                        className="block mb-2 text-sm font-medium text-gray-900"
                        htmlFor=""
                      >
                        Yıl Eklensin
                      </label>
                      <Switch
                        checked={includeYear}
                        onChange={(e) => setIncludeYear(e)}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        className="block mb-2 text-sm font-medium text-gray-900"
                        htmlFor=""
                      >
                        Ay Eklensin
                      </label>
                      <Switch
                        checked={includeMonth}
                        onChange={(e) => setIncludeMonth(e)}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        className="block mb-2 text-sm font-medium text-gray-900"
                        htmlFor=""
                      >
                        Gün Eklensin
                      </label>
                      <Switch
                        checked={includeDay}
                        onChange={(e) => setIncludeDay(e)}
                      />
                    </div>
                  </div>

                  <div className="col-span-2 flex justify-end gap-[20px]">
                    <button
                      type="submit"
                      className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NumericSettingEdit;
