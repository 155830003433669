import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";

const NumericSettingTab = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState(null);
  const [loading, setLoading] = useState();
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=21`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(`/api/Common/GetConfigNumbers?customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
            })
            .catch((err) => console.error(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);


  console.log(data);

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-full bg-gray-100">
          <div className="mb-5 flex items-center gap-2">
            <FaStarOfLife size={15} />
            <h1 className="text-3xl font-semibold title">Numaralandırma Ayarları</h1>
          </div>
          <div className="relative overflow-x-auto pt-7">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Adı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Ön ek
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Yapılandırma Adı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Örnek No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    İslemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {item.name}
                      </th>
                      <td className="px-6 py-4">{item.prefix}</td>
                      <td className="px-6 py-4">{item.configName}</td>
                      <td className="px-6 py-4">{item.sampleNumber}</td>

                      <td className="flex items-center px-6 py-4 space-x-3">
                        {permissions.reportPermission && (
                          <Link to={`/numericSettingEdit/${item.configID}`}>
                            <a
                              title="Makine düzenle"
                              className="font-medium text-[#050A27] hover:underline cursor-pointer"
                            >
                              <TbEdit
                                className="hover:scale-110 transition-transform duration-200"
                                size={24}
                              />
                            </a>
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default NumericSettingTab;
