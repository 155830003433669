import React, { useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import Footer from "../../components/bars/Footer";
import Breadcrumb from "../../components/bars/Breadcrumb";
import TaskMainTemplate from "./TaskMainTemplate";
import TemplateTasks from "./TemplateTasks";

const TemplateTaskTabs = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTabTemplateTasks") || "TaskMainTemplate"
  ); // Varsayılan olarak local storage'dan veya 'AssignerUser' olarak başlat

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem("activeTabTemplateTasks", tabName); // Sekme değiştiğinde local storage'a kaydet
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        <div className="flex-grow">
          <Breadcrumb
            breadcrumbData={[{ label: "Şablon Görevler", href: "" }]}
          />
          <div className="m-12 min-h-screen ">
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-[-30px]">
              <ul className="flex flex-wrap -mb-px ">
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "TaskMainTemplate"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("TaskMainTemplate")}
                  >
                    Şablon Görev Kategorileri
                  </a>
                </li>
                <li className="me-2">
                  <a
                    href="#"
                    className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                      activeTab === "TemplateTasks"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                    }`}
                    onClick={() => handleTabClick("TemplateTasks")}
                  >
                    Şablon Görevler
                  </a>
                </li>
              </ul>
            </div>
            <div>
              {activeTab === "TaskMainTemplate" && < TaskMainTemplate/>}
              {activeTab === "TemplateTasks" && <TemplateTasks/>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TemplateTaskTabs;
