import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/bars/Footer";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";

const WorkFlowAddForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [customerID, setCustomerID] = useState(null);
  const [form, setForm] = useState({
    title: "",
    name: "",
    description: "",
  });
  const [token, setToken] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/workFlow", { state: form });
  };
  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=11`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        <div className="flex-grow">
          <Breadcrumb
            breadcrumbData={[
              { label: "İş Akışları", href: "/workFlowList" },
              { label: "İş Akışı Oluştur", href: "" },
            ]}
          />
          <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-[90%] bg-gray-100">
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title">İş Akışı Oluştur</h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-1 gap-7 items-center w-full"
            >
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  İş Akış Başlığı :
                </label>
                <input
                  value={form.title}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      title: e.target.value,
                      name: e.target.value,
                    })
                  }
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  İş Akış Adı :
                </label>
                <input
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  İş Akış Açıklaması :
                </label>
                <textarea
                  value={form.description}
                  onChange={(e) =>
                    setForm({ ...form, description: e.target.value })
                  }
                  id="description"
                  rows="4"
                  className="resize-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Açıklama yazınız..."
                ></textarea>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "20px",
                }}
                className="col-span-3"
              >
                <button
                  type="submit"
                  className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                >
                  Devam Et
                  <i className="fa-solid fa-arrow-right ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkFlowAddForm;
