import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import TasksByAssignedUser from "./TasksByAssignedUser";
import TasksByCreatorUser from "./TasksByCreatorUser";
import TasksAllByUser from "./TasksAllByUser";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import Breadcrumb from "../../components/bars/Breadcrumb";
import PeriodicTasks from "./PeriodicTasks";

const Tasks = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
    cityName: "desc",
    districtName: "desc",
    address: "desc",
    description: "desc",
  });
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "AssignerUser"
  ); // Varsayılan olarak local storage'dan veya 'AssignerUser' olarak başlat

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem("activeTab", tabName); // Sekme değiştiğinde local storage'a kaydet
  };

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=13`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Task/GetTasks?customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize]);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Görevler", href: "" },
              ]}
            />

          <div className="m-12 min-h-screen">
            <div className="add-button">
              <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-[-30px] ">
                <ul className="flex flex-wrap -mb-px ">
                  <li className="me-2">
                    <a
                      href="#"
                      className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                        activeTab === "AllUser"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                      }`}
                      onClick={() => handleTabClick("AllUser")}
                    >
                      Tüm Görevler
                    </a>
                  </li>
                  <li className="me-2">
                    <a
                      href="#"
                      className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                        activeTab === "CreatorUser"
                        ? "text-blue-800 border-blue-300"
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                      }`}
                      onClick={() => handleTabClick("CreatorUser")}
                      >
                      Atadığım Görevler
                    </a>
                  </li>
                  <li className="me-2">
                    <a
                      href="#"
                      className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                        activeTab === "AssignerUser"
                        ? "text-blue-800 border-blue-300 "
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                      }`}
                      onClick={() => handleTabClick("AssignerUser")}
                      >
                      Atanmış Görevler
                    </a>
                  </li>
                  <li className="me-2">
                    <a
                      href="#"
                      className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                        activeTab === "PeriodicTasks"
                        ? "text-blue-800 border-blue-300 "
                        : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                      }`}
                      onClick={() => handleTabClick("PeriodicTasks")}
                      >
                      Periyodik Görevler
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                {activeTab === "AssignerUser" && <TasksByAssignedUser />}
                {activeTab === "CreatorUser" && <TasksByCreatorUser />}
                {activeTab === "AllUser" && <TasksAllByUser />}
                {activeTab === "PeriodicTasks" && <PeriodicTasks />}
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Tasks;
