import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import * as XLSX from "xlsx";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import WayBillPdfModal from "./WayBillPdfModal";
import WayBillPdfDetailModal from "./WayBillPdfDetailModal";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const WayBills = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [openPdfDetailModal, setOpenPdfDetailModal] = useState(false);
  const [dataItem, setDataItem] = useState(null);
  const [selectedWayBillIDs, setSelectedWayBillIDs] = useState([]);
  const [pdfData, setPdfData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
    title: "desc",
    districtName: "desc",
    address: "desc",
    taxOffice: "desc",
    taxNumber: "desc",
    wayBillNo: "desc",
    wayBillDate: "desc",
    expiryDate: "desc",
    totalPrice: "desc",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=39`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/WayBill/GetWayBills?customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              res.data.items.wayBillDate = formatDate(
                res.data.items.wayBillDate
              );
              res.data.items.expiryDate = formatDate(res.data.items.expiryDate);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => setData(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize]);

  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (
          columnName === "name" ||
          columnName === "title" ||
          columnName === "address" ||
          columnName === "taxOffice" ||
          columnName === "taxNumber" ||
          columnName === "wayBillrNo" ||
          columnName === "wayBillDate" ||
          columnName === "expiryDate" ||
          columnName === "totalPrice"
        ) {
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            sortOrderCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };
  /////////////////////////////////////////////

  const handleDelete = async (wayBillD, customerID) => {
    try {
      Swal.fire({
        title: "İptal etmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/WayBill/DeleteWayBill/${wayBillD}?customerID=${customerID}`
            )
            .then(() => {
              // Silme işlemi başarılı oldu, veriyi güncelle
              setData((prevData) =>
                prevData.filter((item) => item.wayBillD !== wayBillD)
              );
              Swal.fire({
                title: "Başarıyla iptal edildi!",
                text: "İrsaliye iptal edildi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "İrsaliye silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // Tarih geçersizse boş bir string dönebiliriz
    }

    const tarihObjesi = new Date(dateString);

    if (isNaN(tarihObjesi.getTime())) {
      return ""; // Tarih geçerli değilse yine boş string dönebiliriz
    }

    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    return `${gun}/${ay}/${yil}`;
  };

  // PUT ITEMS STATUS
  const handleStatus = async (wayBillD) => {
    Swal.fire({
      title: "İrsaliyeyi onaylamak istediğinize emin misiniz?",
      icon: "warning",
      confirmButtonColor: "#0C692F",
      cancelButtonColor: "#B10F0F",
      showCancelButton: true,
      confirmButtonText: "Evet, değiştir!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const token =
            localStorage.getItem("token") || sessionStorage.getItem("token");

          if (!token) {
            throw new Error("Token bulunamadı, lütfen giriş yapın.");
          }

          await api.put(
            `/api/WayBill/PutWayBillStatus/PutWayBillStatus?customerID=${customerID}&waybillID=${wayBillD}&status=1567`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "İrsaliye onaylandı!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  // ITEMS TO EXCEL
  const handleExportData = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfData(response.data);

      const worksheet = XLSX.utils.json_to_sheet(data);

      // Örneğin, A, B, C sütunları için genişlik ayarı
      worksheet["!cols"] = [
        { wch: 10 }, // A sütunu genişliği
        { wch: 10 }, // B sütunu genişliği
        { wch: 10 }, // C sütunu genişliği
        { wch: 10 }, // D sütunu genişliği
        { wch: 10 }, // E sütunu genişliği
        { wch: 20 }, // F sütunu genişliği
        { wch: 20 }, // G sütunu genişliği
        { wch: 100 }, // H sütunu genişliği
        { wch: 60 }, // I sütunu genişliği
        { wch: 10 }, // J sütunu genişliği
        { wch: 10 }, // K sütunu genişliği
        { wch: 20 }, // L sütunu genişliği
        { wch: 20 }, // M sütunu genişliği
        { wch: 20 }, // N sütunu genişliği
        { wch: 20 }, // O sütunu genişliği
        { wch: 10 }, // P sütunu genişliği
        { wch: 10 }, // Q sütunu genişliği
        { wch: 10 }, // R sütunu genişliği
        { wch: 10 }, // S sütunu genişliği
        { wch: 20 }, // T sütunu genişliği
        { wch: 10 }, // U sütunu genişliği
        { wch: 10 }, // V sütunu genişliği
        { wch: 10 }, // W sütunu genişliği
        { wch: 100 }, // X sütunu genişliği
        { wch: 50 }, // Y sütunu genişliği
        { wch: 10 }, // Z sütunu genişliği
        { wch: 100 }, // AA sütunu genişliği
        { wch: 50 }, // AB sütunu genişliği
        { wch: 10 }, // AC sütunu genişliği
        { wch: 20 }, // AD sütunu genişliği
        { wch: 20 }, // AE sütunu genişliği
        { wch: 40 }, // AF sütunu genişliği
        { wch: 10 }, // AG sütunu genişliği
        // ve böyle devam eder...
      ];
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const fileName = `${response.data}.xlsx`; // Dosya adını ve uzantısını belirtiyoruz
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
    }
  };
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const fetchDocumentNumber = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token"); // Token'ı localStorage'dan alabilirsiniz
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header'ını ekliyoruz
          },
        }
      );

      // Mevcut veri ile API'den gelen veriyi birleştiriyoruz

      setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfModal(true); // Modal'ı açıyoruz
    }
  };

  const fetchSingleItem = async (id) => {
    setLoading(true);

    try {
      const response = await api.get(
        `/api/WayBill/Get?id=${id}&customerID=${customerID}`
      );
      setDataItem(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfDetailModal(true); // Modal'ı açıyoruz
    }
  };

  const handleCheckboxChange = (wayBillID) => {
    setSelectedWayBillIDs(
      (prev) =>
        prev.includes(wayBillID)
          ? prev.filter((id) => id !== wayBillID) // Seçilmişse çıkar
          : [...prev, wayBillID] // Seçilmemişse ekle
    );
  };

  const handleWaybillsToInvoice = async () => {
    if (selectedWayBillIDs.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Uyarı",
        text: "Lütfen en az bir irsaliye seçin!",
      });
      return;
    }
  
    Swal.fire({
      title: "İrsaliyeleri faturaya göndermek istediğinize emin misiniz?",
      icon: "warning",
      confirmButtonColor: "#0C692F",
      cancelButtonColor: "#B10F0F",
      showCancelButton: true,
      confirmButtonText: "Evet, gönder!",
      cancelButtonText: "Hayır, iptal et",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
  
        try {
          const token =
            localStorage.getItem("token") || sessionStorage.getItem("token");
  
          if (!token) {
            throw new Error("Token bulunamadı, lütfen giriş yapın.");
          }
  
          const postData = {
            wayBillIDs: Array.isArray(selectedWayBillIDs)
              ? selectedWayBillIDs
              : [], // Diziyi garanti et
            customerID,
          };
  
  
          await api.post(
            "/api/Invoice/PostWayBillsToInvoice/PostWayBillsToInvoice",
            postData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "İrsaliyeler başarıyla gönderildi!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload();
          });
  
          setSelectedWayBillIDs([]);
        } catch (error) {
          const errorMessageKey = error.response?.data;
          const translatedError = errorMessageKey
            ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
            : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${translatedError}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[{ label: "Satın Alma İrsaliyeleri", href: "" }]}
            />
            <div className="m-12 min-h-screen">
              <div className="add-button">
                {permissions.insertPermission && (
                  <Link to={"/wayBillAdd"}>
                    <button
                      type="button"
                      className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12"
                    >
                      Satın Alma İrsaliye Oluştur
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </Link>
                )}
                {selectedWayBillIDs.length > 0 && (
                  <button
                    type="button"
                    className="butonCl text-white bg-[#050A27] hover:bg-[#050a27da] focus:outline-none focus:ring-4 focus:ring-[#050A27] font-medium rounded-lg text-sm px-12 py-2.5 text-center mr-2 mb-2 h-12"
                    onClick={handleWaybillsToInvoice}
                  >
                    Seçilenleri Faturaya Gönder
                    <i class="fa-regular fa-paper-plane ml-2"></i>
                  </button>
                )}
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title ">
                    Satın Alma İrsaliyeleri
                  </h1>
                </div>
                <div className="flex justify-between pt-2">
                  <input
                    className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                    type="text"
                    placeholder="İrsaliye ara.."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                  {permissions.showPricePermission && (
                    <div className="flex gap-5 mr-4">
                      <a
                        onClick={fetchDocumentNumber}
                        className="font-medium text-[#050A27] hover:underline cursor-pointer"
                      >
                        <FaRegFilePdf
                          className="hover:scale-110 transition-transform duration-200"
                          size={30}
                        />
                      </a>
                      <button onClick={handleExportData}>
                        <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                          <RiFileExcel2Line
                            className="hover:scale-110 transition-transform duration-200"
                            size={35}
                          />
                        </a>
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="relative overflow-x-auto mb-5">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27]">
                    <tr>
                      <th scope="col" className="px-3 py-3"></th>
                      <th
                        scope="col"
                        className="px-3 py-3 cursor-pointer"
                        onClick={() => handleSort("name")}
                      >
                        İrsaliye Adı {sortOrder.name === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 cursor-pointer"
                        onClick={() => handleSort("title")}
                      >
                        Başlık {sortOrder.title === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 cursor-pointer"
                        onClick={() => handleSort("address")}
                      >
                        Adres {sortOrder.address === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 cursor-pointer"
                        onClick={() => handleSort("taxOffice")}
                      >
                        Vergi Dairesi{" "}
                        {sortOrder.taxOffice === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 cursor-pointer"
                        onClick={() => handleSort("taxNumber")}
                      >
                        Vergi Numarası{" "}
                        {sortOrder.taxNumber === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 cursor-pointer"
                        onClick={() => handleSort("wayBillNo")}
                      >
                        İrsaliye Numarası{" "}
                        {sortOrder.wayBillNo === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 cursor-pointer"
                        onClick={() => handleSort("wayBillDate")}
                      >
                        Tarih {sortOrder.wayBillDate === "asc" ? "▲" : "▼"}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 cursor-pointer"
                        onClick={() => handleSort("expiryDate")}
                      >
                        Son Tarih {sortOrder.expiryDate === "asc" ? "▲" : "▼"}
                      </th>
                      <th scope="col" className="px-3 py-3">
                        Toplam Fiyat
                      </th>
                      <th scope="col" className="px-3 py-3">
                        İşlemler
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data
                        ?.filter(
                          (item) =>
                            item.name
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.title
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.address
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.taxOffice
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.taxNumber
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.wayBillNo
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.wayBillDate
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.expiryDate
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.totalPrice
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                        )
                        .map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b hover:bg-gray-50"
                          >
                            <td className="px-3 py-4">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500"
                                  disabled={item.invoiceID}
                                  checked={selectedWayBillIDs.includes(
                                    item.wayBillD
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(item.wayBillD)
                                  }
                                />
                              </td>
                            <th
                              scope="row"
                              className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap"
                              title={item.name}
                            >
                              {truncateText(item.name, 20)}
                            </th>
                            <td title={item.title} className="px-3 py-4">
                              {truncateText(item?.title, 20)}
                            </td>
                            <td title={item.address} className="px-3 py-4">
                              {truncateText(item?.address, 30)}
                            </td>
                            <td className="px-3 py-4">{item?.taxOffice}</td>
                            <td className="px-3 py-4">{item?.taxNumber}</td>
                            <td className="px-3 py-4">{item?.wayBillNo}</td>
                            <td className="px-3 py-4">
                              {formatDate(item.wayBillDate)}
                            </td>
                            <td className="px-3 py-4">
                              {formatDate(item.expiryDate)}
                            </td>
                            {permissions.showPricePermission ? (
                              <td className="px-3 py-4">{item.totalPrice}</td>
                            ) : (
                              <td className="px-3 py-4"></td>
                            )}
                            <td className="flex items-center px-3 py-4 space-x-3">
                              {item.status === 1566 && (
                                <button
                                  onClick={() => handleStatus(item.wayBillD)}
                                  className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                >
                                  <FaCheck
                                    className="hover:scale-110 transition-transform duration-200"
                                    color="#050A27"
                                    size={21}
                                  />
                                </button>
                              )}
                              {permissions.showPricePermission && (
                                <a
                                  onClick={() => fetchSingleItem(item.wayBillD)}
                                  className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                >
                                  <FaRegFilePdf
                                    className="hover:scale-110 transition-transform duration-200"
                                    size={24}
                                  />
                                </a>
                              )}

                              {permissions.reportPermission && (
                                <Link to={`/wayBillDetail/${item.wayBillD}`}>
                                  <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                    <FaRegEye
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                </Link>
                              )}
                              {permissions.updatePermission && (
                                <Link to={`/wayBillEdit/${item.wayBillD}`}>
                                  {" "}
                                  <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                    <TbEdit
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                </Link>
                              )}
                              {permissions.deletePermission && (
                                <button
                                  onClick={() => {
                                    handleDelete(
                                      item.wayBillD,
                                      item.customerID
                                    );
                                  }}
                                >
                                  <a className="font-medium text-[#050A27] hover:underline cursor-pointer">
                                    <RiDeleteBin6Line
                                      className="hover:scale-110 transition-transform duration-200"
                                      size={24}
                                    />
                                  </a>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td
                          colSpan={10}
                          className="px-6 py-4 text-center text-2xl font-medium"
                        >
                          Henüz kayıtlı bir veri yoktur.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <nav
                className="mt-2 float-right my-5"
                aria-label="Page navigation example"
              >
                <ul className="flex items-center -space-x-px h-10 text-sm">
                  <li>
                    <button
                      onClick={prevPage}
                      disabled={currentPage === 1}
                      className={`flex items-center justify-center px-4 h-10 ${
                        currentPage === 1
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500"
                      }`}
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="w-2.5 h-2.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 1 1 5l4 4"
                        />
                      </svg>
                    </button>
                  </li>
                  {renderPageNumbers()}
                  <li>
                    <button
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                      className={`flex items-center justify-center pl-4 h-10 ${
                        currentPage === totalPages
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500"
                      }`}
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="w-2.5 h-2.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
      {openPdfModal === true && (
        <WayBillPdfModal
          data={data}
          pdfData={pdfData}
          onCancel={() => setOpenPdfModal(false)}
        />
      )}
      {openPdfDetailModal === true && (
        <WayBillPdfDetailModal
          data={dataItem}
          onCancel={() => setOpenPdfDetailModal(false)}
        />
      )}
      <Footer />
    </>
  );
};

export default WayBills;
