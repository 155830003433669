import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import Breadcrumb from "../../components/bars/Breadcrumb";

const CollectPaymentSuppDetail = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));
    if (user && user.country) {
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=45`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(`/api/Account/GetAccountCredit?id=${params.id}&customerID=${user.country}`)
            .then((res) => setData(res.data))
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    return `${gun}/${ay}/${yil}`;
  };


  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Tedarikçiler", href: "/suppliers" },
                { label: "Cari Hareketleri", href: `/suppliersMovements/${data.accountID}` },
                { label: "Cari Hareket Detayı", href: "" },
              ]}
            />
            <div className="w-full p-12 min-h-screen flex flex-col gap-7 items-center my-14">
              <div className="bg-[#050A27] w-full max-w-screen-2xl overflow-hidden">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-3xl font-semibold text-white">
                    {data?.registerName}
                  </h3>
                  <p className="mt-1 max-w-2xl text-base font-medium text-gray-400">
                    {data?.accountName}
                  </p>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <label
                        htmlFor="userSelect"
                        className="text-sm font-medium text-gray-500"
                      >
                        Borç :
                      </label>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.borc}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                       Alacak :
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.alacak}
                      </dd>
                    </div>
                    <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Ödeme Tipi :
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data.typeString ? data.typeString : "Belirtilmemiş"}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Açıklama :
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {data?.description}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
        </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CollectPaymentSuppDetail;
