import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const waybillModes = [
  { value: 0, label: "Yok" },
  { value: 1, label: "Gönderim" },
  { value: 2, label: "Alım" },
  { value: 3, label: "Hepsi" },
];

const EwaybillSettingsTab = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [useEWayBill, setUseEWayBill] = useState(false);
  const [eWayBillUsername, setEWayBillUsername] = useState("");
  const [eWayBillPassword, setEWayBillPassword] = useState("");
  const [eWayBillInstitutionID, setEWayBillInstitutionID] = useState("");
  const [eWayBillPrefix, setEWayBillPrefix] = useState("");
  const [eWayBillTicket, setEWayBillTicket] = useState("");
  const [numberOfPaymentDay, setNumberOfPaymentDay] = useState(Number());
  const [numberOfReceiveDay, setNumberOfReceiveDay] = useState(Number());
  const [eWayBillMode, setEWayBillMode] = useState({});

  useEffect(() => {
    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=21`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(`/api/Customer/GetCustomerEWayBill?id=${user.country}`)
            .then((res) => {
              const matchedMode = waybillModes.find(
                (mode) => mode.value === res.data.eWayBillMode
              ) || { value: res.data.eWayBillMode, label: "" };

              setEWayBillMode(matchedMode); // Eşleşen mode'u state'e ata
              setUseEWayBill(res.data.useEWayBill);
              setEWayBillUsername(res.data.eWayBillUsername);
              setEWayBillPassword(res.data.eWayBillPassword);
              setEWayBillInstitutionID(res.data.eWayBillInstitutionID);
              setEWayBillPrefix(res.data.eWayBillPrefix);
              setEWayBillTicket(res.data.eWayBillTicket);
              setNumberOfPaymentDay(res.data.numberOfPaymentDay);
              setNumberOfReceiveDay(res.data.numberOfReceiveDay);
            })
            .catch((err) => console.error(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const postData = {
    customerID,
    useEWayBill,
    eWayBillMode: eWayBillMode?.value,
    eWayBillUsername,
    eWayBillPassword,
    eWayBillInstitutionID,
    eWayBillPrefix,
    eWayBillTicket,
    numberOfPaymentDay,
    numberOfReceiveDay,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .put("/api/Customer/PutCustomerEWayBill/PutCustomerEWayBill", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "E-İrsaliye ayarları güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      })

      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${translatedError}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen w-full bg-gray-100">
          <div className="mb-5 flex items-center gap-2">
            <FaStarOfLife size={15} />
            <h1 className="text-3xl font-semibold title">E-İrsaliye Ayarları</h1>
          </div>
          <form
            className="grid grid-cols-2 gap-7 items-center w-full"
            onSubmit={handleSubmit}
          >
            <div>
              <label
                htmlFor=""
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                E-İrsaliye Modu :
              </label>
              <Select
                maxMenuHeight={200}
                classNamePrefix="custom-select"
                placeholder="Seçiniz..."
                options={waybillModes}
                value={eWayBillMode}
                onChange={(e) => setEWayBillMode(e)}
              />
            </div>
            <div>
              <label
                htmlFor="eWayBillUsername"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                E-İrsaliye Kullanıcı Adı :
              </label>
              <input
                type="text"
                id="eWayBillUsername"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={eWayBillUsername}
                onChange={(e) => setEWayBillUsername(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="eWayBillPassword"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                E-İrsaliye Şifre :
              </label>
              <input
                type="text"
                id="eWayBillPassword"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={eWayBillPassword}
                onChange={(e) => setEWayBillPassword(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="eWayBillInstitutionID"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Kurum Kimliği :
              </label>
              <input
                type="text"
                id="eWayBillInstitutionID"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={eWayBillInstitutionID}
                onChange={(e) => setEWayBillInstitutionID(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="eWayBillPrefix"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                E-İrsaliye Ön Eki :
              </label>
              <input
                type="text"
                id="eWayBillPrefix"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={eWayBillPrefix}
                onChange={(e) => setEWayBillPrefix(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="eWayBillTicket"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                E-İrsaliye Etiketi :
              </label>
              <input
                type="text"
                id="eWayBillTicket"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={eWayBillTicket}
                onChange={(e) => setEWayBillTicket(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="numberOfPaymentDay"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Ödeme Vade :
              </label>
              <input
                type="number"
                id="numberOfPaymentDay"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={numberOfPaymentDay}
                onChange={(e) => setNumberOfPaymentDay(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="numberOfReceiveDay"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Alacak Vade :
              </label>
              <input
                type="number"
                id="numberOfReceiveDay"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={numberOfReceiveDay}
                onChange={(e) => setNumberOfReceiveDay(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor=""
              >
                E-İrsaliye :
              </label>
              <Switch
                checked={useEWayBill}
                onChange={(e) => setUseEWayBill(e)}
              />
            </div>

            <div className="col-span-2 flex justify-end gap-[20px]">
              <button
                type="submit"
                className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
              >
                Kaydet
                <i className="fa-regular fa-floppy-disk ml-2"></i>
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default EwaybillSettingsTab;
